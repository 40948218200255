.cms-home {

  .banners {
    position: relative;
    padding-top: 9.4rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    .banner {
      height: calc(#{$vh} - 94px);
      width: 100%;
      position: relative;
    }

    .banner-image {
      overflow: hidden;
      position: absolute;
      height: 100%;
      width: 100%;
      .banner-image-overlay {
        background: rgba(0,0,0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width:100%;
        z-index: 2;
      }
      img {
        position: absolute;
        top: 0;
        height: 100%;
        width: auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
      }
    }

    .banner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 2.5rem;
    }

    h2.headline {
      font-size: 3rem;
      line-height: 3.8rem;
      color: $PureWhite;
      font-family: $Muli;
      font-weight: 300;
      margin-top: 0;
      text-transform: uppercase;
    }

    .banner-text {
      display: block;
      color: $PureWhite;
      margin-bottom: 3rem;
      width: 100%;
    }

    a {
      @include btn;
      width: auto;
      display: inline-block;
    }

    //Slick Slider
    .slick-arrow {
      display: none !important;
    }

    .slick-dots {
      position: absolute;
      bottom: 2rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 100%;
      li {
        margin: 0 1rem;

        &.slick-active {
          button {
            background: $PureWhite;
          }
        }
        button {
          height: 1.7rem;
          width: 1.7rem;
          font-size: 0;
          border: 0.1rem solid $PureWhite;
          border-radius: 50%;
          background: none;
          padding: 0;
        }
      }
    }
  }

  .main-content {
    padding-top: 2rem;
  }

  .primary-copy-block {
    text-align: center;
    padding: 5rem 2rem;

    h1 {
      color: $WTCBlack;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: $WTCLightGray;
    }
  }

  .customer-support-blocks {
    padding: 0 2rem;
    .customer-service-block,
    .partnership-block,
    .product-support-block {
      padding: 3.5rem 3rem;

      h4 {
        color: $WTCBlue;
      }
    }

    .partnership-block {
      background: #EFEFEF;
    }

    .customer-service-icon,
    .partnership-icon,
    .product-support-icon {
      display: block;
      width: 3.5rem;
      height: 3.5rem;
    }

    .customer-service-icon {
      background: url(../images/customer-service-icon.svg) center center no-repeat;
      background-size: contain;
    }

    .partnership-icon {
      background: url(../images/partnership-service-icon.svg) center center no-repeat;
      background-size: contain;
    }

    .product-support-icon {
        background: url(../images/product-support-icon.svg) center center no-repeat;
        background-size: contain;
    }
  }

  .cta-block {

    > a {
      margin: 1.5rem 0;

      &:hover,
      &:focus,
      &:active {
        .wrapper {
          img {
            -webkit-transform: scale(1.25);
            transform: scale(1.25);
          }
        }
      }
    }

    position: relative;
    .wrapper {
      overflow: hidden;
      position: relative;

      .wrapper-overlay {
        background-color: rgba(0, 124, 195, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        z-index: 2;
      }

      img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }
    }

    h3 {
      color: $PureWhite;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      z-index: 3;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);

              &:after {
                content: '';
                display: block;
                position: relative;
                bottom: -1rem;
                width: 10rem;
                height: 0.6rem;
                background: $WTCNeonBlue;
              }
    }
  }

  .featured-products-wrapper {
    margin: 5rem 0;
    display: block;

    .block-title {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-bottom: 4rem;

      .block-title-border {
        position: absolute;
        width: 10rem;
        height: 100%;
        border: 0.1rem solid $WTCBlue;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
      }

      p {
        color: $WTCBlue;
        font-size: 2.2rem;
        line-height: 2.8rem;
        font-weight: 300;
        margin: 2rem 0;
        text-align: center;
        width: 100%;
        background: $PureWhite;
        position: relative;
      }
    }

    .block-instructions {
      margin-bottom: 2rem;
      img {
        display: block;
        margin: auto;
      }

      p {
        color: $WTCBlue;
        text-align: center;
        width: 100%;
      }
    }

    ol.product-items {
      position: relative;
      margin: 0 1.5rem;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;

      li.product-item {
        .product-item-info {
          position: relative;

          button.approved {
            border: 0.1rem solid #e0e0e0;
            background: none;
            border-radius: 0.3rem;
            position: absolute;
            top: 0;
            left: 0;
            padding: 1rem;
            z-index: 5;

            .approved-checkmark {
              width: 2.2rem;
              height: 2.2rem;
              display: block;
              margin: auto;

              background: url(../images/approved-checkmark.svg) center center no-repeat;
              background-size: contain;
            }

            &:hover,
            &:active,
            &:focus {
              background: none;
              border: 0.1rem solid #e0e0e0;
            }
          }
        }

        .product-item-photo {
          width: 100%;
          display: block;
          padding: 0 4rem;

          img {
            width: 100%;
            height: auto;
          }
        }


        .product-item-details {
          padding: 0 4rem;
          margin-top: 1rem;
          .product-item-link {
            color: $WTCBlue;
            font-size: 1.7rem;
            line-height: 2.4rem;
            margin-bottom: 0.5rem;
          }

          a.towishlist {
            color: $WTCBlue;
            font-size: 1.7rem;
            line-height: 2.4rem;
            text-decoration: underline;
            margin-bottom: 0.5rem;
            display: block;
          }

          .price-final_price {
            color: $WTCBlue;
            font-weight: 300;
            font-size: 2.2rem;
            line-height: 2.8rem;
            margin-bottom: 0.5rem;
            display: block;
          }
        }
      }
    }

    .slick-slide img {
      margin: auto;
    }

    .slick-arrow {
      position: absolute;
      font-size: 0;
      line-height: 0;
      padding: 0;
      background: none;
      border: none;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);

      &.slick-prev {
        left: 0;
        &:before {
          content: '';
          display: block;
          width: 3rem;
          height: 3rem;
          background: url(../images/mobile/arrow-icon-left.svg) center center no-repeat;
          background-size: contain;
        }
      }

      &.slick-next {
        right: 0;
        &:before {
          content: '';
          display: block;
          width: 3rem;
          height: 3rem;
          background: url(../images/mobile/arrow-icon-right.svg) center center no-repeat;
          background-size: contain;
        }
      }
    }
  }

  .banner {
    .banner-image {
      img {
        max-width: none;
      }
    }
  }

}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 76.8rem) {

}


/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .cms-home {
    .banners {
      padding-top: 11.2rem;

      .slick-arrow-wrapper {
        display: block;
        width: 100%;
        max-width: 117rem;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
      }

      .slick-arrow {
        display: block !important;
        position: absolute;
        z-index: 5;
        font-size: 0;
        line-height: 0;
        padding: 0;
        background: none;
        border: none;


        &.slick-next {
          right: 1.5rem;

          &:before {
            display: block;
            content: '';
            width: 2rem;
            height: 2rem;
            background: url(../images/nav-arrow-icon-down.svg) center center no-repeat;
            background-size: contain;
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
          }
        }

        &.slick-prev {
          left: 1.5rem;

          &:before {
            display: block;
            content: '';
            width: 2rem;
            height: 2rem;
            background: url(../images/nav-arrow-icon-down.svg) center center no-repeat;
            background-size: contain;
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
          }
        }
      }

      .banner {
        height: 55rem;
        width: 100%;
        position: relative;
        .banner-image {
          img {
            z-index: 1;
          }
        }

        .banner-content {
          max-width: 114rem;
          text-align: center;
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
        }

        h2.headline {
          font-size: 4.2rem;
          line-height: 5.3rem;
        }

        .banner-text {
          font-size: 2rem;
          line-height: 3.3rem;
          max-width: 80%;
          margin: 0 auto 3rem;
        }
      }
    }

    .customer-support-blocks {
      margin: 4rem 2rem;
      display: inline-block;
      -webkit-box-shadow: 0rem 2rem 4rem #e0e0e0;
              box-shadow: 0rem 2rem 4rem #e0e0e0;
    }

    .cta-block {
      margin: 4rem 2rem;
      > a {
        padding: 1.5rem;
        .wrapper {
          -webkit-box-shadow: 0rem 2rem 4rem #e0e0e0;
                  box-shadow: 0rem 2rem 4rem #e0e0e0;
        }
      }
    }

    .featured-products-wrapper {

      .block-instructions {
        display: none;
      }

      .slick-track {
        width: 100% !important;
      }

      ol.product-items {
        padding: 0;
        list-style: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;

        li.product-item {
          width: 47%;
          padding: 0;
          float: left;
          margin: 0 0 3rem;

          &:nth-child(even) {
            float: right;
          }

          .product-item-photo {
            @include make-md-column(6, 0);
            padding: 0;
          }

          .product-image-photo {
            width: 100%;
            height: auto;
          }

          .product-item-info {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;

            .product-item-photo {
              @include make-md-column(6, 0);
              padding: 0;
            }

            .product-item-details {
              margin-top: 0;
              @include make-md-column(6, 0);
              padding: 0 0 0 1.5rem;
            }
          }
        }
      }
    }
  }
}
