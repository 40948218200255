.page-with-filter,
.catalogsearch-result-index {
  h1.page-title {
    text-align: center;
    margin: 4rem 0;
  }

  .sidebar-one{
    display:none;
  }

  label,
  label.label {
    text-transform: none;
    color: $WTCBlue;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  .bottom-sorter{
    .toolbar-products{
      .pages{
        width:100%;
      }
      .sorter, .toolbar-limiter{
        display: none;
      }
    }
  }

  .toolbar-products {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    .toolbar-sorter {
      margin-bottom: 3rem;
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1;
    }

    .toolbar-limiter {
      margin-bottom: 3rem;
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2;
    }

    .pages {
      margin-bottom: 3rem;
      -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
              order: 3;

              ul.pages-items {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;

                li {
                  font-size: 1.4rem;
                  line-height: 1;
                  border: 0.1rem solid $WTCBlue;

                  -webkit-transition: all 0.2s linear;
                  transition: all 0.2s linear;

                  &:hover {
                    background: $WTCDarkBlue;

                    p,
                    a {
                      color: $PureWhite;
                    }
                  }

                  p,
                  a {
                    font-size: 1.4rem;
                    line-height: 1;
                    margin: 0;
                    padding: 1rem 1.3rem;
                    display: block;
                  }

                  a {
                    color: $WTCBlue;
                    text-decoration: none;
                  }

                  &.current {
                    background: $WTCBlue;

                    p {
                      color: $PureWhite;
                    }
                  }

                  &.pages-item-previous {
                    border-radius: 0.3rem 0 0 0.3rem;
                  }

                  &.pages-item-next {
                    border-radius: 0 0.3rem 0.3rem 0;
                  }
                }
              }
    }

    .modes,
    .toolbar-amount {
      display: none;
    }
  }

  ol.product-items {
    padding: 0;
    list-style: none;

    li {
      max-width: 50rem;
      margin: auto;
      display: block;

      .product-item-info {
        position: relative;
        button.approved {
          border: 0.1rem solid #e0e0e0;
          background: none;
          border-radius: 0.3rem;
          position: absolute;
          top: 0;
          left: 0;
          padding: 1rem;
          z-index: 5;

          .approved-checkmark {
            width: 2.2rem;
            height: 2.2rem;
            display: block;
            margin: auto;

            background: url(../images/approved-checkmark.svg) center center no-repeat;
            background-size: contain;
          }

          &:hover,
          &:active,
          &:focus {
            background: none;
            border: 0.1rem solid #e0e0e0;
          }
        }
      }

      img.product-image-photo {
        width: 100%;
        height: auto;
      }

      .product-item-link {
        font-size: 1.7rem;
        line-height: 2.4rem;
        color: $WTCBlue;
        font-weight: normal;
      }

      .price-final_price {
        font-size: 2.2rem;
        line-height: 2.8rem;
        color: $WTCBlue;
      }

      a.towishlist {
        display: block;
        font-size: 1.7rem;
        line-height: 2.4rem;
        color: $WTCBlue;
        text-decoration: underline;
        margin-bottom: 1.5rem;
      }

      button.tocart {
        width: 100%;
      }
    }
  }
}


/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 768px) {
  .page-with-filter,
  .catalogsearch-result-index {
    .main {
      margin-bottom: 4rem;
      > .container {
        padding: 0;
      }
    }

    ol.product-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;

      li {
        width: 50%;
        margin: 3rem 0;

        &:nth-child(odd) {
          padding-right: 2rem;
        }

        &:nth-child(even) {
          padding-left: 2rem;
        }

        button.tocart {
          width: auto;
        }
      }
    }

    .toolbar-products {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;

      .toolbar-limiter,
      .toolbar-sorter {
        margin-bottom: 0;
        @include make-md-column(3,0);
      }

      .toolbar-sorter {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
      }

      .toolbar-limiter {
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
      }

      .pages {
        margin: 3rem 0 0;
        @include make-md-column(5, 0);
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
      }
    }
  }
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .page-with-filter,
  .catalogsearch-result-index {
    .main {
      width: 100%;
    }

    ol.product-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      li {
        @include make-md-column(6, 0);
        max-width: none;

        &:nth-child(odd) {
          padding-right: 2rem;
        }

        &:nth-child(even) {
          padding-left: 2rem;
        }

        a.product-item-photo,
        .product-item-details {
          display: block;
          float: left;
          @include make-md-column(6, 0);
          display: inline-block;
        }

        a.product-item-photo {
          padding-right: 1rem;
        }

        .product-item-details {
          padding-left: 1rem;
        }

        .price-final_price {
          font-size: 2.6rem;
          line-height: 3.3rem;
        }
      }
    }
  }
}

/*** EXTRA LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 1200px) {

}
