// Reference these classes to only target the customer account login pages seperate from modals
.customer-account-login,
.multishipping-checkout-login {

  h1.page-title {
    margin-bottom: 0;
  }

  .block-customer-login,
  .block-new-customer {
    .block-title {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-family: $Muli;
      color: $WTCBlue;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      padding: 3rem 0 1.5rem;
    }

    .block-content {
      p {
        margin: 0;
        font-size: 1.4rem;
        line-height: 2rem;

        &.copy {
          margin-bottom: 1rem;
        }
      }
    }

    .control {
      margin: 0.5rem 0;

      input[type="password"],
      input[type="email"] {
        width: 100%;
      }
    }

    .actions-toolbar {
      .primary {
        button.login {
          @include btn-primary();
          @include btn-large();
          width: 100%;
          margin: 2rem 0 0;
        }

        a.create {
          @include btn();
          @include btn-primary();
          @include btn-a-large();
          display: inline-block;
          margin: 2rem 0 3rem;
          height: 4.4rem;

          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }
        }
      }

      .secondary {
        a.remind {
          display: block;
          color: $WTCBlack;
          margin: 2rem 0;
          text-align: center;
        }
      }
    }
  }

  .block-new-customer {
    border-top: 0.2rem solid $WTCGray1;
  }

  .padding-wrapper {
    padding: 0 2rem;
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 768px) {
  .customer-account-login .main-content,
  .multishipping-checkout-login .main-content {

    .login-container {
      margin: auto;
    }

    .block-customer-login,
    .block-new-customer {
      width: 50%;
      float: left;
      padding: 2rem 10% 4rem 0;
    }

    .block-new-customer {
      border-top: none;
    }
  }
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .customer-account-login .main-content,
  .multishipping-checkout-login .main-content {
    .block-title {
      padding-top: .5rem
    }

    .actions-toolbar {
      @include make-row();
      > .primary, > .secondary {
        @include make-md-column(6);
        float: left;
      }
      > .secondary {
        a.remind {
          display: block;
          color: $WTCBlack;
          text-align: left;
          margin-top: 3rem;
        }
      }
    }

    .block-new-customer {
      .actions-toolbar {
        .primary {
          @include make-md-column(12);

          a {
            width: auto;
          }
        }
      }
    }
  }
}
