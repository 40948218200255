.breadcrumbs {
  margin: 1rem 0;
  ul {
    width: 100%;
    border-bottom: 0.1rem solid #D8D8D8;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 1.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 1.3rem;
        line-height: 1.9rem;
        position: relative;
        text-decoration: underline;
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: -0.3rem;
          right: -1.5rem;
          border-right: 0.1rem solid #D8D8D8;
          height: 2.5rem;
          transform: rotate(30deg);

          &:hover,
          &:focus,
          &:active {
            color: #D8D8D8;
          }
        }
      }

      strong {
        font-size: 1.3rem;
        line-height: 1.9rem;
        color: $WTCBlue;
        font-weight: normal;
      }
    }
  }
}

.catalog-category-view {
  .main-content {
    > .container {
      padding: 0;
    }
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 76.8rem) {
  
}


/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {

}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 1200px) {

}
