/* --------------------- MOBILE STYLES --------------------- */


/* --- Customer Checkout Page --- */
.checkout-index-index {

  /* --- BEGIN GENERAL STYLES --- */
  :focus {
    outline: none;
  }

  .row {
    margin: 0;
  }

  .main-content {
    padding: 0;
  }

  .label {
    color: #333333;
    font-style: normal;
    font-weight: 400;
    line-height: 1.428571;
    font-size: 1.4rem;
  }

  select {
    line-height: 1.2;
  }

  .page-title-wrapper {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1rem;
  }

  .columns {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  .container {
    width: 100%;
    max-width: 117rem;
  }

  .page-main {
    margin-top: 0;
    padding: 0;
  }

  .column.main {
    width: 100%;
  }

  .page-wrapper {
    padding: 0;
  }

  .header.content {
    display: block;
  }

  fieldset {
    min-width: 0.1rem;

    span.note {
      display: block;
      width: 100%;
    }
  }

  .authentication-wrapper {
    float: right;
    margin-right: 1.5rem;
    max-width: 50%;
    position: relative;
    margin-top: -5.5rem;

    aside {
      z-index: 101;

      .modal-inner-wrap {
        padding: 2.5rem;

        .modal-header {
          border: none;
        }

        .modal-content {
          border: none;
          -webkit-box-shadow: none;
                  box-shadow: none;
        }
      }
    }
  }

  .field {
    margin: 1rem 0;

    &.street {
      margin-bottom: 0;

      .control {
        .label {
          display: none;
        }

        .field:first-of-type {
          margin: 0;
        }
      }
    }

    label,
    .label {
      font-size: 1.4rem;
      line-height: 2.1rem;
      font-weight: bold;
    }

    input {
      max-width: 50rem;
      width: 100%;
    }

    input[type="checkbox"] {
      width: auto;
    }
  }

  form {
    div[name="shippingAddress.country_id"],
    div[name="shippingAddress.region_id"],
    div[name="billingAddresscheckmo.region_id"],
    div[name="billingAddresscheckmo.country_id"] {
      .control {
        max-width: 50rem;
        position: relative;
        @include glyphicon-chevron-down();

        &:before {
          @include glyphicon();
          position: absolute;
          right: 1rem;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 2rem;
          font-size: 1.25rem;
          color: $WTCBlack;
          pointer-events: none;
        }
      }
    }
  }


  .actions-toolbar {
    div.primary {
      margin-right: 1rem;
      margin-top: 1rem;
      display: inline-block;

      button.action-update {
        display: inline-block;
        width: auto;
      }

      button.action-cancel {
        display: inline-block;
        width: auto;
        text-decoration: underline;
      }

      button.action {
        @include btn-primary();

        &.action-cancel {
          @include btn-black();
        }
      }
    }

    div.secondary {
      display: inline-block;

      a {
        font-family: "Proxima Nova";
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 2.4rem;
        color: #4B4446;
        display: inline-block;
        position: relative;
        border-bottom: 0.1rem solid $WTCBlue;
        font-family: "Proxima Nova";
        margin: 1rem 0;

        &:hover {
          color: $WTCBlue;
          border: none;
          cursor: pointer;
          text-decoration: none;
          border-bottom: 0.1rem solid #FFFFFF;
        }
      }
    }

    button {
      &.primary {
        @include btn-primary();
      }
    }

  }


  /* --- END GENERAL STYLES --- */

  /* --- BEGIN MOBILE HEADER STYLES --- */
  .mobile-links li a {
    height: 7rem;
  }

  .page-header {
    margin: 0;
    border: none;
    background: $WTCBlue;
    -webkit-box-shadow: 0 0.1rem 0.2rem 0 rgba(0,0,0,0.2);
            box-shadow: 0 0.1rem 0.2rem 0 rgba(0,0,0,0.2);
    padding: 1.5rem 0;
    width: 100%;
    max-width: none;

    .logo {
      margin-left: 1.5rem;
      max-width: 15rem;
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .opc-wrapper .form-discount .field .label, .bundle-options-container .block-bundle-summary .product-image-container, .bundle-options-container .block-bundle-summary .product.name, .bundle-options-container .block-bundle-summary .stock, .product-item-actions .actions-secondary > .action span, .special-price .price-label, .table-comparison .cell.label.remove span, .table-comparison .cell.label.product span, .block.related .field.choice .label, .cart.table-wrapper .col.qty .label, .minicart-wrapper .action.showcart .text, .minicart-wrapper .action.showcart .counter-label, .checkout-index-index .page-title-wrapper, .checkout-onepage-success .page-title-wrapper, .checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .field.street .field.additional .label, .block-balance .balance-price-label, .page-product-downloadable .product-options-wrapper .fieldset .legend.links-title, .page-product-giftcard .fieldset.giftcard > .legend, .opc-wrapper .form-giftcard-account .field .label, .form-add-invitations .fields .label, .filter-options-content .filter-count-label, .table-comparison .wishlist.split.button > .action.split span, .product-items .wishlist.split.button > .action.split span, .page-multiple-wishlist .page-title-wrapper .page-title, .block-wishlist-search-form .form-wishlist-search .fieldset > .legend, .block.newsletter .label, .review-date .review-details-label, .customer-review .product-details .rating-average-label, .block-reorder .product-item .label, .action.skip:not(:focus), .page-header .switcher .label, .page-footer .switcher .label, .products-grid.wishlist .product-item .comment-box .label {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 0.1rem;
      margin: -0.1rem;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 0.1rem;
    }

  /* --- BEGIN CHECKOUT OPC STYLES --- */
  .opc-progress-bar {
    display: none;
  }

  .opc-wrapper {
    display: block;
    position: relative;
    float: left;
    width: 100%;

    .opc {
      padding: 0 1.5rem 2rem;
      list-style: none;
    }

    .step-title {
        border-bottom: 0.1rem solid #cccccc;
        border-top: none;
      }

      .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
          padding-bottom: 1rem;
          color: #333;
          font-weight: 300;
          font-size: 2.6rem;
          line-height: 1.1;
          position: relative;
        }
  }

    .opc-block-shipping-information .ship-to, .opc-block-shipping-information .ship-via {
      margin: 0 0 2rem;
    }

    .opc-block-shipping-information .shipping-information-title {
      border-bottom: 0.1rem solid #ccc;
      margin: 0 0 2rem;
      position: relative;

      .action-edit {
      top: 0;
      display: none;
      text-decoration: none;
      margin: 0;
      position: absolute;
      right: 0;
      padding: 0;
      background: none;
      border: 0;
      border-radius: 0;
      font-weight: 400;
      -webkit-box-shadow: none;
              box-shadow: none;

      @include glyphicon-edit();

      span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 0.1rem;
        margin: -0.1rem;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 0.1rem;
      }

      &:before {
        @include glyphicon();
        -webkit-font-smoothing: antialiased;
        font-size: 1.8rem;
        line-height: 2rem;
        color: $WTCBlue;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
      }
    }
    }

  .opc-wrapper .form-login, .opc-wrapper .form-shipping-address {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
  }

  .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    visibility: hidden;
  }

  .opc-wrapper .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
      padding-bottom: 1rem;
      color: #333333;
      font-weight: 300;
      font-size: 2.6rem;
    }

  .opc-block-summary {
    .items-in-cart > .title {
        border-bottom: 0.1rem solid #cccccc;
        padding: 1rem 4rem 1rem 0;
        cursor: pointer;
        display: block;
        text-decoration: none;
        margin-bottom: 0;
        position: relative;

        strong {
            font-size: 1.8rem;
            font-weight: 300;
            margin: 0;
        }
    }

    table {
      .mark {
        background: #fff;
        padding-left: 0;
      }
    }
  }

  .opc-block-summary > .title {
      display: block;
  }
  /* --- END CHECKOUT OPC STYLES --- */

  .product-image-container {
      display: inline-block;
      max-width: 100%;
  }

  .minicart-items .product > .product-item-photo, .minicart-items .product > .product-image-container {
      float: left;
  }

  .minicart-items {
    .product-item-name {
        font-weight: 400;
        margin: 0 0 1rem;
    }

    .product-item-details {
        padding-left: 8.8rem;
        color: #333;

        .details-qty {
          margin-top: 1rem;

          .label {
            display: inline-block;
            font-size: 1.4rem;
            line-height: 2rem;

            span {
              padding: 0;
            }
          }
        }
    }

    .product-item {
      padding: 2rem 0;
      display: inline-block;
      width: 100%;

      &:not(:first-child) {
        border-top: 0.1rem solid #cccccc;
      }
    }
  }

  .opc-block-summary .product-item .product-item-inner {
      display: table;
      margin: 0 0 1rem;
      width: 100%;
  }

  .opc-block-summary .product-item .product-item-name-block {
      display: table-cell;
      padding-right: 0.5rem;
      text-align: left;
  }

  .minicart-items .product > .product-item-photo, .minicart-items .product > .product-image-container {
      float: left;
  }

  .product-image-container {
      display: inline-block;
      max-width: 100%;
  }

  .product-image-wrapper {
      display: block;
      height: 0;
      overflow: hidden;
      position: relative;
      z-index: 1;
      border-radius: 0.4rem;
  }

  .products-list .product-image-wrapper, .cart.table-wrapper .product-image-wrapper, .minicart-items .product-image-wrapper, .block-giftregistry-shared-items .product-image-wrapper {
      height: auto;
      padding: 0 !important;
  }

  .opc-block-summary .items-in-cart > .title:after {
    -webkit-font-smoothing: antialiased;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: inherit;
    content: "\e114";
    @include glyphicon();
    margin: 0.3rem 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}

.opc-block-summary .items-in-cart > .title:after {
    position: absolute;
    right: 0;
    top: 1.5rem;
}

.opc-block-summary .items-in-cart.active > .title:after {
    content: "\e113";
}

  .column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0;
  }

  .opc-block-summary .product-item .subtotal {
      display: table-cell;
      text-align: right;
  }

  .opc-block-summary .product-item .price {
    font-size: 1.6rem;
    font-weight: 400;
  }

  /* --- BEGIN OPC ESTIMATE STYLES --- */

  .opc-estimated-wrapper {
    background: #f4f4f4;
    border-bottom: 0.1rem solid #cccccc;
    border-top: 0.1rem solid #cccccc;
    margin-bottom: 2rem;
    padding: 1.8rem 1.5rem;
    float: left;
    width: 100%;
    position: relative;
  }

  .opc-estimated-wrapper .estimated-block {
    font-size: 1.8rem;
    float: left;
    font-weight: 700;
  }

  .opc-estimated-wrapper .estimated-block .estimated-label {
    display: block;
    margin: 0 0 0.5rem;
  }

  .minicart-wrapper {
    display: inline-block;
    position: relative;
    float: right;
  }

  .opc-estimated-wrapper .minicart-wrapper button.action.showcart {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
  }

  .minicart-wrapper .action.showcart .counter.qty {
    background: $WTCBlue;
    color: $PureWhite;
    height: 2.4rem;
    line-height: 2.4rem;
    border-radius: 0.2rem;
    display: inline-block;
    margin: 0.3rem 0 0;
    min-width: 1.8rem;
    overflow: hidden;
    padding: 0 0.3rem;
    text-align: center;
    white-space: normal;

    span {
      color: $PureWhite;
      position: relative;
      top: 0;
      left: 0;
      background: none;
    }
  }

  .minicart-wrapper .action.showcart .counter.qty {
    background: $WTCBlue;
    color: $PureWhite;
    height: 2.4rem;
    line-height: 2.4rem;
    border-radius: 0.2rem;
    display: inline-block;
    margin: 0.3rem 0 0;
    min-width: 1.8rem;
    overflow: hidden;
    padding: 0 0.3rem;
    text-align: center;
    white-space: normal;
  }

  .minicart-wrapper .action.showcart {
    @include glyphicon-shopping-cart;
    &:before {
      @include glyphicon;
      -webkit-font-smoothing: antialiased;
      font-size: 2.2rem;
      line-height: 2.8rem;
      color: #8f8f8f;
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
      position: relative;
      top: 0.5rem;
      right: 0.5rem;
    }
  }


  .opc-wrapper {
    ol.opc {
      margin: 0;
    }

    button {
      width: 100%;
    }
  }

  .opc-estimated-wrapper:before {
    content: '';
    display: table;
  }

  .shipping-information-title {
    line-height: 1.1;

    span {
      margin-right: 2rem;
    }
  }

  .billing-address-details {
    .action-edit-address {
      @include btn();
      @include btn-black();
      margin-right: 2rem;
      margin-top: 1rem;
    }
  }

  /* --- END OPC ESTIMATE STYLES --- */

  /*--- BEGIN ADDRESS MODAL ---*/

  .field.addresses .shipping-address-items {
    .search-addresses-wrapper {
      text-align: center;
      padding: 6rem 0 2rem;
      color: $WTCBlue;
      font-size: 1.7rem;
      line-height: 2.5rem;

      button {
        width: 100%;
        margin-top: 1rem;
        padding: 0;
        background: none;
        border: none;
        color: $WTCGray1;
        text-decoration: underline;
      }
    }
  }

  /* --- BEGIN ORDER SUMMARY STYLES --- */
  .custom-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    left: 4.4rem;

    &._show {
      visibility: visible;
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto;

      .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }

    .modal-inner-wrap {
        background-color: #ffffff;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        height: auto;
        min-height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-box-shadow: 0 0 1.2rem 0.2rem rgba(0, 0, 0, 0.35);
                box-shadow: 0 0 1.2rem 0.2rem rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition-duration: .3s;
                transition-duration: .3s;
        -webkit-transition-property: -webkit-transform, visibility;
        -webkit-transition-property: visibility, -webkit-transform;
        transition-property: visibility, -webkit-transform;
        transition-property: transform, visibility;
        transition-property: transform, visibility, -webkit-transform;
        -webkit-transition-timing-function: ease-in-out;
                transition-timing-function: ease-in-out;
        width: auto;
      }
  }

  .opc-sidebar {
    .minicart-items {
      .product-item-inner {

      }

      .product.options {

        &.active {
          span > span {
            @include glyphicon-arrow-up();
          }

          .content {
            margin-top: 1rem;

            .label {
              font-size: 1rem;
            }

            .item-options {
              margin-top: 1rem;
            }
          }
        }

        span > span {
          position: relative;
          @include glyphicon-arrow-down;
          &:before {
            @include glyphicon;
            -webkit-font-smoothing: antialiased;
            font-size: 1.2rem;
            line-height: 1.2rem;
            color: inherit;
            margin: 0 1rem;
            position: relative;
            top: -0.1rem;
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
          }
        }
      }
    }
  }

  .opc-block-summary {
    background: $OffWhite;
    margin: 0 0 2rem;
    padding: 2.2rem 3rem;

    table.table-totals {
      caption.table-caption {
        display: none;
      }
    }

    .title {
      border-bottom: 0;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      padding-bottom: 0;
    }

    .items-in-cart > .title {
      border-bottom: 0.1rem solid #cccccc;
      padding: 1rem 4rem 1rem 0;
      cursor: pointer;
      display: block;
      text-decoration: none;
      margin-bottom: 0;
      position: relative;

      strong {
        font-size: 1.8rem;
        font-weight: 300;
        margin: 0;
      }
    }

    .minicart-items-wrapper {
      margin: 0 -1.5rem 0 0;
      max-height: 37rem;
      padding: 0 1.5rem 0 0;
      border: 0;
      overflow-y: auto;

      ol.minicart-items {
        padding-left: 0;
        margin: 0;
      }
    }
  }

  .column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0;
  }

  .minicart-items .product > .product-item-photo, .minicart-items .product > .product-image-container {
    float: left;
  }

  .product-image-container {
    display: inline-block;
    max-width: 100%;
  }

  .opc-summary-wrapper {

    .modal-content {
      border: none;
      -webkit-box-shadow: none;
              box-shadow: none;

      .opc-block-summary {
        background: $PureWhite;
      }
    }
  }

  .opc-block-shipping-information {
      padding: 2.2rem 3rem;
  }

  /* --- END ORDER SUMMARY STYLES --- */

  /* --- BEGIN SHIPPING METHOD STYLES --- */

  .table-checkout-shipping-method {
    thead {
      th {
        display: none;
      }
    }

    tbody {
      tr {
        &:first-child {
          td {
              border-top: none;
          }
        }

        td {
           border-top: 0.1rem solid #cccccc;
           padding-bottom: 2rem;
           padding-top: 2rem;
           padding-left: 1rem;
           padding-right: 1rem;

           &:first-child {
               padding-left: 0;
               padding-right: 0;
               width: 2rem;
           }
       }
      }

      .col-price {
        font-weight: 600;
      }
    }
  }


  .opc-wrapper {
    .shipping-address-item {
     display: none;
     border: 0.2rem solid transparent;
     font-size: 1.4rem;
     line-height: 3rem;
     margin: 0 0 2rem;
     padding: 2rem 0;
     -webkit-transition: 0.3s border-color;
     transition: 0.3s border-color;
     width: 100%;
     position: relative;
     vertical-align: top;
     word-wrap: break-word;

     &:nth-of-type(1),
     &:nth-of-type(2)  {
       display: block;
     }

     &.selected-item {
       padding: 1.5rem 3.5rem 1.5rem 1.8rem;
       border-bottom-width: 0.2rem;
       border-color: $WTCBlue;
     }
   }

   .action-select-shipping-item {
     margin: 2rem 0 0;
   }

   .action-show-popup {
     margin: 0 0 2rem;
   }
  }

  /* --- END SHIPPING METHOD STYLES --- */


  /* --- BEGIN PAYMENT METHOD STYLES --- */
  .checkout-payment-method {
    .payment-method-content {
        padding: 0 0 2rem 2.2rem;
    }

    .payment-option {
      padding: 0 0 1rem;

      .payment-option-title {
          border-top: 0.1rem solid #cccccc;
          padding: 1rem 2.2rem 0;
          cursor: pointer;
          margin: 0;
      }
    }

    input[type="radio"] {
      display: inline-block;
      width: auto;
      margin-right: 1rem;
    }
  }

  .payment-method-braintree {
    .credit-card-types {
      list-style: none;
      display: block;
      float: left;
      padding: 0;

      li {
        display: block;
        float: left;
        margin-right: 1rem;
      }
    }

    .field-tooltip {
      .field-tooltip-action {
        position: relative;
        padding-right: 2rem;

        &:hover {
          color: $WTCBlue;
          cursor: pointer;
        }

        &:after {
          -webkit-font-smoothing: antialiased;
            font-size: 1.2rem;
            line-height: 1.2rem;
            color: inherit;
            content: "\f078";
            font-family: 'FontAwesome';
            margin: 0.3rem 0 0;
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            position: absolute;
            right: 0;
            top: 0.2rem;
        }
      }

      &._active {
        .field-tooltip-action {
          &:after {
              content: "\f077";
          }
        }

        .field-tooltip-content {
          display: block;
        }
      }

      margin-top: 1rem;

      .field-tooltip-content {
        display: none;
      }
    }

    fieldset .field .label {
      display: inline-block;
      width: 100%;
      margin: 1rem 0 0;
    }

    .hosted-control {
      width: 100%;
      display: block;
      font-family: 'Proxima N W01SC Reg';
      color: #282B35;
      font-size: 1.125rem;
      line-height: 1.375rem;
      border-radius: 0.2rem;
      border-left-style: initial;
      border-top-style: initial;
      border-right-style: initial;
      border-bottom-style: initial;
      background: #FFFFFF;
      -webkit-box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,0.2), 0 0.1rem 0.2rem 0 rgba(0,0,0,0.2);
              box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,0.2), 0 0.1rem 0.2rem 0 rgba(0,0,0,0.2);
      border: 0.1rem solid #E0E0E0;
      -webkit-appearance: none;
      height: 3.8rem;
      margin-top: 1rem;
    }
  }

  ul.credit-card-types {
    margin-bottom: 2rem;
    li.item {
      display: inline-block;
      margin: 1rem 1rem 0 0;
    }
  }

  fieldset.ccard {
    div.date {
      .field.month,
      .field.year {
        .control {
          position: relative;
          max-width: 50rem;

          @include glyphicon-arrow-down();

          &:before {
            @include glyphicon();
            font-size: 1.2rem;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            right: 2rem;
            pointer-events: none;
          }
        }
      }
    }
  }

  .find-addresses-btn {
    margin-bottom: 3rem;
  }

  .modal-slide._inner-scroll .modal-inner-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    .modal-header {
      position: relative;
      padding-top: 1.5rem;

      h4 {
        color: $WTCBlue;
      }

      button.action-close {
        border: none;
      }
    }

    .modal-content {
      text-align: left;
      padding: 0 3rem;
      width: 100%;

      .field-tooltip-content {
        margin-top: 1rem;
      }

      .field.choice {
        input[type=checkbox] {
          display: inline-block;
          margin-right: 1rem;
        }

        label {
          display: inline-block;
        }
      }
    }

    .modal-footer {
      button {
        height: 4rem;
      }
    }
  }

  .find-addresses-modal {
    .modal-dialog {
      max-width: 40rem;
      width: 90%;
      margin: 0;
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
    }

    .modal-content {
      padding: 1.5rem;
    }

    .modal-header {
      border: none;
      padding: 0;
    }

    .modal-body {
      padding: 1rem 0 0;
    }

    .modal-footer {
      border-color: $WTCBlue;
      padding: 1rem 0 0;
    }

    h4.modal-title {
      font-family: $Muli;
      color: $WTCBlue;
      font-size: 2.6rem;
      line-height: 3.3rem;
      font-weight: 300;
    }

    button.close {
      color: $WTCBlue;
      opacity: 1;
      font-size: 2.5rem;

      &:hover,
      &:active,
      &:focus {
        background: none;
      }
    }

    .live-search-wrapper {
      width: 100%;
      position: relative;

      input {
        padding: 1rem 1rem 1rem 4rem;
        width: 100%;
        border: 0.1rem solid $WTCBlack;
      }

      i {
        position: absolute;
        color: $WTCBlue;
        left: 1.5rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
      }
    }

    ul {
      overflow-y: auto;
      max-height: 50vh;
      margin: 0;

      li {
        padding: 1rem;
        margin: 1rem 0 0;
        border-top: 0.1rem solid $WTCBlack;

        &:first-child {
          border: none;
        }

        button {
          display: none;
        }

        &.selected {
          border: 0.1rem solid $WTCBlue;
        }
      }
    }

    .modal-footer {
      .cancel {
        background: none;
        border: none;
        color: $WTCGray1;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          background: none;
          border: none;
        }
      }
    }
  }

  .payment-option {

    &._active {
      .payment-option-title {
        #block-shipping-heading, #block-discount-heading, #block-giftcard-heading {
          @include glyphicon-minus;
        }
      }
    }

    .payment-option-title {

      #block-shipping-heading, #block-discount-heading, #block-giftcard-heading {
        color: $WTCBlue;
        display: inline-block;
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 2rem;
        position: relative;
        padding-right: 3rem;

        @include glyphicon-plus;

        &:before {
          @include glyphicon;
          -webkit-font-smoothing: antialiased;
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: #8f8f8f;
          margin: 0 0 0 1rem;
          vertical-align: middle;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center;
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
        }
      }
    }

    .payment-option-content {
      padding: 0 2.2rem;
    }
  }

  /* --- END PAYMENT METHOD STYLES --- */
}


/* --- Checkout Success Styles --- */
.checkout-onepage-success {
  .columns {
    max-width: 117rem;
    padding: 1.5rem;

    a.continue {
      color: $WTCBlue;
    }
  }
}


@media all and (min-width: 64rem), print {
  .checkout-index-index {

    /* --- BEGIN SHIPPING METHOD STYLES --- */

    .opc-wrapper {
      button {
        width: auto;
        margin-bottom: 2rem;
        vertical-align: middle;
      }
      .action-show-popup{
        margin-right: 1rem;
      }
      .shipping-address-items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
            -ms-flex-align: stretch;
                align-items: stretch;
      }

      .shipping-address-item {
        padding: 2rem 3.5rem 2rem 2rem;
        width: 50%;
      }
    }

    /* --- END SHIPPING METHOD STYLES --- */

  }
}


@media all and (min-width: 76.8rem), print {
  .checkout-index-index {
    /* --- BEGIN GENERAL STYLES --- */
    .opc-sidebar .modal-content{
      overflow: hidden;
    }
    .columns {
      display: block;
      max-width: 117rem;
      margin: auto;
    }

    .page-header {
      .header.content {
        max-width: 117rem;
        margin: auto;
        padding: 0;
      }
    }

    .authentication-wrapper {
      width: 33.33333333%;
      text-align: right;
      margin-top: -7rem;

      .authentication-dropdown {
        background-color: $PureWhite;
        border: 0.1rem solid #aeaeae;
        -webkit-transform: scale(1, 0);
        -webkit-transform-origin: 0 0;
        -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0.1s;
        position: absolute;
        text-align: left;
        top: 100%;
        bottom: auto;
        left: auto;
        right: auto;
        transform: scale(1, 0);
        transform-origin: 0 0;
        -webkit-transition: visibility 0s linear .1s, -webkit-transform linear .1s;
        transition: visibility 0s linear .1s, -webkit-transform linear .1s;
        transition: transform linear .1s, visibility 0s linear .1s;
        transition: transform linear .1s, visibility 0s linear .1s, -webkit-transform linear .1s;
        visibility: hidden;
        width: 100%;


        &._show {
          z-index: 100;
          -webkit-transform: scale(1, 1);
          -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0s;
          transform: scale(1, 1);
          -webkit-transition: visibility 0s linear 0s, -webkit-transform linear .1s;
          transition: visibility 0s linear 0s, -webkit-transform linear .1s;
          transition: transform linear .1s, visibility 0s linear 0s;
          transition: transform linear .1s, visibility 0s linear 0s, -webkit-transform linear .1s;
          visibility: visible;
        }

        .messages {
          &.error {
            color: $WTCBlue;
          }
        }
      }
    }

    /* --- END GENERAL STYLES --- */

    /* Checkout Method */
    .payment-method{
      .payment-method-content {
          display: none;
      }
      &._active{
        .payment-method-content{
          display: block;
        }
      }
    }


    /* --- Customer Checkout Page --- */
    .checkout-container {
        margin: 9rem 0 2rem;
    }

    .opc-progress-bar {
      margin: 2rem 0;
      counter-reset: i;
      display: block;
      font-size: 0;
      padding: 0 1.5rem;
    }

    .opc-estimated-wrapper {
        display: none;
    }

  .opc-progress-bar-item {
    margin: 0;
    width: 18.5rem;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
  }

  .opc-progress-bar-item > span {
      display: inline-block;
      padding-top: 4.5rem;
      width: 100%;
      word-wrap: break-word;
      color: #e4e4e4;
      font-weight: 300;
      font-size: 1.8rem;
  }

    .opc-progress-bar-item:before {
      background: #e4e4e4;
      top: 1.9rem;
      content: '';
      height: 0.7rem;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .opc-progress-bar-item:first-child:before {
      border-radius: 0.6rem 0 0 0.6rem;
    }

    .opc-progress-bar-item._active > span {
      color: #333;
    }

    .opc-progress-bar-item._active:before {
      background: $WTCBlue;
    }

    .opc-wrapper {
      width: 66.66666667%;
      float: left;
      -ms-flex-order: 1;
      -webkit-box-ordinal-group: 2;
              order: 1;
      padding-right: 3rem;
    }

    .opc-sidebar {
      margin: 0 0 2rem;
      width: 33.33333333%;
      padding-right: 3rem;
      float: right;
      -ms-flex-order: 2;
      -webkit-box-ordinal-group: 3;
              order: 2;
              visibility: visible;
      position: relative;
      bottom: initial;
      min-width: initial;
      right: initial;
      top: initial;
      left: initial;

      .modal-content {
        -webkit-box-shadow: none;
                box-shadow: none;
        border: none;
      }

      .modal-header {
        border: none;
        display: none;
      }

      .minicart-items {
        padding-left: 0;
      }

      .opc-block-shipping-information {
          padding: 2.2rem 0;
      }
    }

    .opc-block-summary {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      background: none;

      span.title {
        padding: 0 0 1rem;
        color: #333;
        font-weight: 300;
        font-size: 2.6rem;
        line-height: 1.1;
        margin-bottom: 0;
        border-bottom: 0.1rem solid #cccccc;
      }

      .table-totals {
        text-align: left;
        padding-top: 1rem;

        tr {
          border-bottom: 0.1rem solid #cccccc;

          td {
            border: none;
          }
        }

        .table-caption {
          display: none;
        }

        .mark {
          border: 0;
          font-weight: 400;
          padding: 0.6rem 0;

          span {
            padding: 0;
          }

          tbody {
            tr {
              td {
                border: none;
              }
            }
          }
        }

        .mark .value {
          color: #999999;
          display: block;
          font-weight: 400;
        }

        .grand .mark {
              font-size: 1.8rem;
              border-top: 0.1rem solid #cccccc;
              padding-right: 1rem;
              font-weight: 400;
          }

          .grand .amount {
              font-size: 1.8rem;
              border-top: 0.1rem solid #cccccc;
          }
        }

        .product-item-name-block {
          .label {
            padding-left: 0;
          }
        }
      }

      .items-in-cart > .title {
        padding: 0 4rem 1rem 0;
      }


    .custom-slide .modal-inner-wrap {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              -webkit-box-shadow: none;
                      box-shadow: none;

              .modal-header {
                padding: 0;
              }
    }

    .opc-progress-bar-item > span:before, .opc-progress-bar-item > span:after {
    background: #e4e4e4;
    height: 3.8rem;
    margin-left: -1.9rem;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    width: 3.8rem;
    border-radius: 50%;
    content: '';
    left: 50%;
    position: absolute;
    }

    .opc-progress-bar-item._active > span,
    .opc-progress-bar-item._active > span {

      &:before,
      &:after {
        @include glyphicon;
        line-height: 1.4;
        font-size: 1.8rem;
        color: #333;
        border: 0.6rem solid $WTCBlue;
        background: $PureWhite;
      }

      &:after {
        content: "\e013";
      }
    }

    .opc-progress-bar-item._complete {
      &:hover {
        color: $WTCBlue;
        cursor: pointer;

        &:before {
          background: $WTCBlue;
        }

        span {
          color: $WTCBlue;
        }

        span:before,
        span:after {
            color: #333;
            background: $PureWhite;
            border: 0.6rem solid $WTCBlue;
        }
      }
    }


    .opc-progress-bar-item._complete > span {
      :before {
        @include glyphicon;
        color: #333;
        background: $PureWhite;
        line-height: 1.4;
        font-size: 1.8rem;
        border: 0.6rem solid #e4e4e4;
      }
    }


    .opc-progress-bar-item > span:before, .opc-progress-bar-item > span:after {
        color: #e4e4e4;
        height: 3.8rem;
        margin-left: -1.9rem;
        -webkit-transition: background 0.3s;
        transition: background 0.3s;
        width: 3.8rem;
        border-radius: 50%;
        background: white;
        border: 0.6rem solid #e4e4e4;
        content: '';
        left: 50%;
        position: absolute;
        top: 0;
      }

    .authentication-wrapper {
    width: 33.33333333%;
    text-align: right;
    }

    .opc-wrapper .shipping-address-item {
    width: 50%;
    padding-bottom: 9rem;
    }

    .opc-wrapper .action-select-shipping-item {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              width: 80%;
              margin: 0;
    }

    .shipping-address-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch;
    }

    /* --- BEGIN PAYMENT METHOD STYLES --- */
    .checkout-payment-method {

      .payment-option {
        padding: 0 0 1rem;

        .payment-method-title {
          padding-top: 1rem;
          padding-bottom: 0;
          margin: 0;
        }
      }

      .payment-method-title {
          padding: 2rem 0;
          margin: 0;
      }

      .payment-method + .payment-method .payment-method-title {
          border-top: 0.1rem solid #cccccc;
      }

      .payment-option-title {
        padding-left: 2.2rem;
      }

      .opc-payment {
      }
    }

    /* --- END PAYMENT METHOD STYLES --- */
  }
}

//IE 11 hack
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .checkout-index-index  .modal-slide._inner-scroll .modal-inner-wrap {
    height: 90%;
  }
}

@media only screen and (min-width : 99.2rem) {
  .multishipping-checkout-addresses {
    .page-wrapper {
      padding-top: 3rem;
    }
  }
}
