.wordpress-homepage-view,
.wordpress-term-view {
  .main-content {
    h1.page-title {
      text-align: center;
    }

    > .container {
      padding: 0;
    }

    > .row {
      > .col-md-3 {
        @include make-xs-column(12, 15);
      }

      > .col-md-9 {
        @include make-xs-column(12, 0);
      }
    }

    .wp-sidebar {
      .block-title {
        display: none;
      }

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
          @include make-xs-column(6, 0);
          margin: 0.8rem 0;

          &:nth-child(odd) {
            padding-right: 1rem;
          }

          &:nth-child(even) {
            padding-left: 1rem;
          }

          &.active {
            a {
              background: $WTCBlue;
              color: $PureWhite;
            }
          }


          a {
            background: $PureWhite;
            color: $WTCBlue;
            border: 1px solid $WTCBlue;
            width: 100%;
            padding: 1rem;
            display: block;
            text-align: center;
            border-radius: 0.3rem;

            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;

            &:hover {
              background: $WTCBlue;
              color: $PureWhite;
              text-decoration: none;
            }
          }
        }
      }
    }

    .post-list-wrapper {
      padding: 0 4rem;
      margin: 4rem 0;

      li {
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0;

        .post-image {
          border-radius: 0.3rem 0.3rem 0 0;
          margin: 0;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }

        .post-entry {
          padding: 3rem 2.5rem;
          border: 0.1rem solid #E8E8E8;
          border-radius: 0 0 0.3rem 0.3rem;
          margin: 0;
          h2.post-name {
            margin-top: 0;
            font-size: 1.7rem;
            line-height: 2.4rem;
            a {
              font-weight: bold;
              color: $WTCBlue;
            }
          }

          .post-readmore {
            a {
              @include btn();
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 768px) {
  .wordpress-homepage-view,
  .wordpress-term-view {
    .main-content {
      .wp-sidebar {
        ul {
          li {
            width: 25%;
            padding: 0 1rem;
          }
        }
      }

      .post-list-wrapper ol {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          width: 50%;

          &:nth-child(odd) {
            padding-right: 1.5rem;
          }

          &:nth-child(even) {
            padding-left: 1.5rem;
          }
        }
      }
    }
  }
}


/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .wordpress-homepage-view,
  .wordpress-term-view {
    .main-content {

      > .row {
        > .col-md-3 {
          padding: 0;
        }
      }

      .wp-sidebar {
        ul {
          li {
            margin: 1.5rem 0;
            &:nth-child(odd),
            &:nth-child(even)  {
              padding: 0 1.5rem;
            }
          }
        }
      }

      .post-list-wrapper {
        padding: 0;
        ol {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 23%;
            flex-direction: row;
            align-items: stretch;
            margin: 2rem 0;
            padding: 0;

            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;

            &:hover,
            &:focus,
            &:active {
              box-shadow: 0rem 2rem 4rem #e0e0e0;
            }

            &:nth-child(odd) {
              padding-right: 0;
            }

            &:nth-child(even) {
              padding-left: 0;
            }

            &.has-feat-img {
              width: 48%;
              margin: 2rem 0;
              padding: 0;

              .post-image {
                @include make-md-column(6, 0);
                position: relative;

                // img {
                //   position: absolute;
                //   top: 50%;
                //   left: 50%;
                //   transform: translate(-50%, -50%);
                //   height: 100%;
                //   width: auto;
                // }
              }

              .post-entry {
                @include make-md-column(6, 0);
                padding: 2.5rem;
              }
            }

            .post-entry {
              @include make-md-column(12, 0);
              padding: 2.5rem;
            }
          }
        }
      }
    }
  }
}
