.checkout-index-index header{
  position: static;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: $WTCBlue;
  padding: 2rem 0 1.5rem;

  .header-left {
    margin-top: 1.5rem;
    padding-right: 0;
  }

  ul.mobile-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
            margin-bottom: 0;

    li {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;

      > button {
        border-radius: 0;
        padding: 0;
        background: none;
        margin: auto;
        border: none;

        &:hover,
        &:active,
        &:focus {
          -webkit-box-shadow: none;
                  box-shadow: none;
        }
      }

      a, button {
        color: $PureWhite;
        font-size: 1.3rem;
        line-height: 1.9rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        &:hover {
          text-decoration: none;
        }

        div {
          width: 4rem;
          height: 4rem;
          background-size: contain;
          display: block;
          margin: auto;

          &.mobile-profile {
            background: url(../images/mobile/profile-icon.svg) center center no-repeat;
          }

          &.mobile-cart {
            background: url(../images/mobile/shopping-cart-icon.svg) center center no-repeat;
          }

          &.mobile-menu {
            background: url(../images/mobile/menu-search-icon.svg) center center no-repeat;

            &.open {
              background: url(../images/mobile/close-icon.svg) center center no-repeat;
            }
          }
        }

        span {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  button.hamb-menu {
    display: none;
  }

  .header-links-wrapper {
    display: none;
    position: fixed;
    top: 9.4rem;
    bottom: auto;
    right: 0;
    left: 0;
    padding: 2.5rem 0;
    background: $PureWhite;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;

            &.open {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              overflow-y: auto;
              overflow-x: hidden;
            }
  }

  .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1;

    .block-search {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1;
      padding: 0 1.5rem;
    }

    nav.navigation {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2;
      width: 100%;
    }
  }

  .utility-menu {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;

    .secondary-navigation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
              padding: 0;

      .page-links {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
                margin: 3rem 0;

                li a {
                  text-transform: uppercase;
                  font-size: 2rem;
                  line-height: 2.5rem;
                  padding: 1.5rem 0;
                  display: block;

                  &:hover,
                  &:focus,
                  &:active {
                    outline: none;
                    text-decoration: none;
                  }
                }
                li.shopping-cart-btn{
                  display: none;
                }
      }

      .social-links {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
                margin: 3rem 0 3rem;

                li {
                  display: inline-block;
                  width: auto;
                  a {
                    font-size: 2.5rem;

                    margin: 0 3.5rem;
                  }
                }
      }
    }
  }

  .mobile-back-btn {
    display: none;
    float: left;
    width: 25%;
    margin-right: 5%;
    padding: 1rem 0;
    text-align: center;
  }

  .block-search {
    margin-bottom: 1.5rem;

    .open-search {
      display: none;
    }

    #search_mini_form {
      display: block;
      width: 100%;
      float: right;
    }

    .field.search {
      display: block;
      float: left;
      width: 85%;

      #search {
        font-size: 1.6rem;
        padding-left: 4rem; //1.5rem 0.8rem 1.5rem 4rem;
        border-radius: 0.5rem 0 0 0.5rem;
        color: $PureWhite;
        display: inline-block;
        width: 100%;
        height: 4.8rem;
        font-weight: 300;
        line-height: 1.8rem;
        background: $WTCDarkBlue url(../images/mobile/search-icon.svg) 1.5rem center no-repeat;
        background-size: 1.7rem 1.7rem;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $PureWhite;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $PureWhite;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $PureWhite;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $PureWhite;
        }
        &:focus{
          border-width: 0;
        }
      }
    }

    .actions {
      display: block;
      float: left;
      width: 15%;
      .action.search {
        display: inline-block;
        background: $WTCLightGray;
        color: $WTCDarkBlue;
        text-transform: uppercase;
        border-radius: 0 0.5rem 0.5rem 0;
        padding: 1.45rem 0;
        width: 100%;
        border: none;

        &:hover,
        &:active,
        &:focus {
          -webkit-box-shadow: none;
                  box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }

  nav.navigation {
    position: relative;
    padding: 0 1.5rem;

    a {
      font-size: 2rem;
      line-height: 2.5rem;
      padding: 1.5rem 0;
      display: block;
      color: $WTCDarkestBlue;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        outline: none;
      }
    }

    ul > li {
      text-transform: uppercase;

      &.parent {

        .mobile-level0-parent-arrow {
          height: 5.5rem;
          width: 5.5rem;
          background: url(../images/mobile/accordian-down-arrow-icon.svg) center center no-repeat;
          position: absolute;
          top: 0;
          right: 0;
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }

        a {
          width: 100%;
        }

        &.level0 {
          > a {
            display: block;
            position: relative;
            width: calc(#{$fullWidth} - 54px);

            &:before {
              display: none;
              content: '';
              height: 2rem;
              width: 2rem;
              background: url(../images/mobile/accordian-down-arrow-icon.svg) center center no-repeat;
              position: absolute;
              bottom:50%;
              right: 0;
              -webkit-transform: rotate(-90deg) translateY(50%);
                      transform: rotate(-90deg) translateY(50%);
            }
          }
        }
      }
    }

    .submenu {
      display: none;
      width: 100%;
      padding: 0 1.5rem;
      &.level0 {
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
      }
    }
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 76.8rem) {
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  header {
    padding: 0;
    .row {
      position: relative;
      padding-top: 3.8rem;
    }

    ul.mobile-header {
      display: none;
    }

    .header-left {
      display: inline-block;
      margin-top: 2.2rem;
    }

    .header-links-wrapper {
      display: inline-block;
      position: static;
      padding: 0;
      background: none;
      top: auto;

      .utility-menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: $WTCDarkBlue;

        .secondary-navigation {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
                  -webkit-box-pack: end;
                      -ms-flex-pack: end;
                          justify-content: flex-end;
                  padding: 0.65rem 1.5rem;
          ul {
            margin: 0;
            &.social-links {
              -webkit-box-ordinal-group: 2;
                  -ms-flex-order: 1;
                      order: 1;

                      li a {
                        font-size: 1.5rem;
                        margin: 0;
                      }
            }

            &.page-links {
              -webkit-box-ordinal-group: 3;
                  -ms-flex-order: 2;
                      order: 2;

              li {
                &:last-child {
                  margin-right: 0;
                }
              }

              li.shopping-cart-btn{
                display: inline-block;
              }

              .cart-count {
                font-family: $Muli;
                &:before {
                  content: '';
                  display: inline-block;
                  position: relative;
                  top: 0.2rem;
                  margin-right: 0.5rem;
                  width: 1.5rem;
                  height: 1.5rem;

                  background: url(../images/mobile/shopping-cart-icon.svg) center center no-repeat;
                  background-size: contain;
                }
              }
            }

            li {
              display: inline-block;
              width: auto;
              margin: 0 1.5rem;

              a,
              span {
                color: $PureWhite;
                font-size: 1.3rem;
                line-height: 1.9rem;
                padding: 0;
                text-transform: uppercase;
                margin: 0;
                font-style: normal;
                display: inline;
              }
            }
          }
        }
      }


      .header-right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: no-wrap;
          flex-wrap: no-wrap;
          position: static;
      nav {
        @include make-md-column(8, 3rem);
        display: inline-block;
        position: static;
        -webkit-box-ordinal-group :2;
            -ms-flex-order :1;
                order :1;

        > ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
                  -webkit-box-pack: end;
                      -ms-flex-pack: end;
                          justify-content: flex-end;
                  margin-bottom: 0;
          li.level0 {
            color: $PureWhite;
            position: relative;
            font-size: 1.5rem;
            line-height: 1.9rem;
            text-transform: uppercase;
            display: inline-block;

            > a {
              padding: 2.75rem 3rem;
              color: $PureWhite;
              display:block;
              position: relative;
              font-size: 1.5rem;
              line-height: 1.9rem;
            }

            &:hover {
              > a {
                text-decoration: underline;
              }
            }

            &.parent {

              &:before,
              &:after {
                display: none;
              }

              > ul.submenu {
                li {
                  &:after {
                    display: none;
                  }
                }
              }

              &:hover {
                > a {
                  text-decoration: none;
                }
              }

              > a {
               position: relative;
               width: 100%;

               &:before {
                 display: block;
                 position: absolute;
                 left: 50%;
                 -webkit-transform: translateX(-50%);
                         transform: translateX(-50%);
                 bottom: 0;
                 background: url(../images/nav-arrow-icon-down.svg) center center no-repeat;
               }
             }
            }

            > ul.submenu {
              display: none;
              -webkit-box-orient:horizontal;
              -webkit-box-direction:normal;
                  -ms-flex-direction:row;
                      flex-direction:row;
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
              position: absolute;
              z-index: 10;
              width: 90rem;
              left: -14.5rem;
              background: $PureWhite;
              border-radius: 0.3rem;
              padding: 3rem 0;
              top: auto;
              padding-left: 33.5rem;
              max-height: 60vh;
              overflow-y: auto;

              -webkit-box-shadow: 0rem 2rem 4rem #e0e0e0;
                      box-shadow: 0rem 2rem 4rem #e0e0e0;


              .submenu-default-img-wrapper {
                background-image:url(../images/wtc-menu.png);
                background-repeat:no-repeat;
                background-position: center;
                background-size: contain;
                width: 30%;
                height: 27rem;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                left: 5%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
              }

              li {
                margin: 0 0 12px 0;
                display: inline-block;
                vertical-align: top;
                width:49%;
                position: static;
                a {
                  text-transform: none;
                  color: $WTCGray1;
                  font-size: 1.6rem;
                  line-height: 2.5rem;
                  color: $WTCGray1;
                  padding: 0;
                  display: block;
                  padding-right:10px;

                  span.desktop-level1-img-wrapper {
                    display: none;
                    width: 30%;
                    height: 90%;
                    position: absolute;
                    left: 5%;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                  }

                  &:before {
                    display: none;
                  }

                  &:hover {
                    color: $WTCBlue;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }

      .block-search {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
                padding-top: 2rem;

                .mobile-back-btn {
                  display: none;
                }
      }
    }
    }

    .block-search {
      display: inline-block;
      @include make-md-column(4, 3rem);

      .field.search {
        #search {
          height: 3rem;
          background: rgba(255,255,255,0.3) url(../images/mobile/search-icon.svg) 1.5rem center no-repeat;
        }
      }

      .actions {
        .action.search {
          padding: 0.55rem 0.75rem;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1170px){
    header{
        .header-links-wrapper{
            .header-right{
                nav{
                    > ul{
                        li.level0 {
                            > a{
                                padding:2rem 2.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
