.catalog-product-view {
  .column.main {
    margin-bottom:3rem;
    > .container {
      padding: 0;
    }
  }

  .swatch-option-tooltip {
    display: none !important;
  }

  .breadcrumbs {
    ul {
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  .page.messages .messages .message {
    margin: 3rem 0 0;
  }

  h1.page-title {
    color: $WTCBlue;
  }

  .product.media {
    position: relative;
    button.approved {
      border: 0.1rem solid #e0e0e0;
      background: none;
      border-radius: 0.3rem;
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem;
      z-index: 5;

      .approved-checkmark {
        width: 3.5rem;
        height: 3.5rem;
        display: block;
        margin: auto;

        background: url(../images/approved-checkmark.svg) center center no-repeat;
        background-size: contain;
      }

      p {
        color: $WTCBlue;
        text-transform: uppercase;
        font-size: 1.3rem;
        line-height: 1.9rem;
        width: 100%;
        text-align: center;
        margin: 1rem 0 0;
      }

      &:hover,
      &:focus,
      &:active {
        background: none;
        border: 0.1rem solid #e0e0e0;
      }
    }
    .fotorama-item {
      	border: 0.1rem solid #E8E8E8;
        border-radius: 0.3rem;
        margin-bottom: 2.5rem;
    }

    .fotorama__nav-wrap {
      padding-bottom: 1rem;
    }

    .fotorama__dot {
      width: 1.2rem;
      height: 1.2rem;
      border: 0.1rem solid $WTCBlue;
    }

    .fotorama__active .fotorama__dot {
       background: $WTCBlue;
    }

    .fotorama__arr {
      width: 4rem;
    }

    .fotorama__stage__frame .fotorama__img {
      width: 80%;
    }

    .fotorama__arr, .fotorama__thumb__arr {
      background: none;
    }

    .fotorama__arr--prev .fotorama__arr__arr {
      background: url(../images/pdp-arrow-icon-left.svg) center center no-repeat;
      outline: none;
    }

    .fotorama__arr--next .fotorama__arr__arr {
      background: url(../images/pdp-arrow-icon-right.svg) center center no-repeat;
      outline: none;
    }
  }

  .product-info-main {
    margin-bottom: 3rem;

    .product-info-stock-sku {
      div.stock {
        display: none;
      }

      div.sku {
        .value {
          font-size: 1.8rem;
          line-height: 2.8rem;
          color: $WTCBlack;
          font-family: $Muli;
          display: block;
          margin-bottom: 1rem;
        }
      }
    }

    .product-options-wrapper .fieldset {
      outline: none;
    }

    .swatch-attribute {
      margin: 1rem 0;
    }

    .swatch-attribute-label {
      color: $WTCBlue;
      font-family: $Muli;
      text-transform: uppercase;
      font-size: 1.7rem;
      line-height: 2.4rem;
    }

    .swatch-option {
      &.text {
        @include btn;
        height: auto;
        max-width: none;
        outline: none;

        &.selected {
          background: $WTCNeonBlue !important;
          border-color: $WTCNeonBlue !important;
        }
      }

      &.color {
        height: 3.4rem;
        width: 3.4rem;
        padding: 0;
        border-radius: 0.3rem;
        border: 0.1rem solid #CCCCCC;
        outline: none;

        &:hover {
          outline: none;
          border: 0.1rem solid #CCCCCC;
        }

        &.selected {
          border: 0.1rem solid $WTCNeonBlue;
        }
      }
    }

    .price-final_price {
      .price {
        color: $WTCBlue;
        font-weight: 300;
        font-size: 2.2rem;
        line-height: 2.8rem;
        font-family: $Muli;
      }
    }

    .product-add-form {
      label.label {
          font-size: 1.7rem;
          line-height: 2.4rem;
          color: $WTCBlue;
          font-weight: bold;
      }

      button.tocart {
        width: 100%;
      }

      .fieldset {
        .field {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          margin: 2rem 0;

          label.label {
            -webkit-box-flex: 25%;
                -ms-flex: 25%;
                    flex: 25%;
          }

          .control {
            -webkit-box-flex: 75%;
                -ms-flex: 75%;
                    flex: 75%;
          }
        }
      }
    }
  }

  .login-cta {
    background: $WTCBlue;
    border-radius: 0.3rem;
    padding: 2.5rem;
    margin-bottom: 1rem;
    width: 100%;
    margin-top: 7rem;
    position: relative;

    p,
    a.contact-cta {
      color: $PureWhite;
      margin: 0;
      font-weight: 300;
    }

    a.btn {
      text-decoration: none;
      margin: 1.5rem 0 3rem;
      font-weight: bold;
    }

    a {
      text-decoration: underline;
    }
  }

  p.heading {
    position: relative;
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: $WTCBlack;
    margin: 0;
    &:after {
      content: '';
      width: 2rem;
      height: 2rem;
      position: absolute;
      right: 0;
      background: url(../images/accordian-down-arrow-icon.svg) center center no-repeat;

      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
    }

    &.active {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .product-specifications {
    p {
      span {
        color: $WTCBlue;
      }
    }
  }

  .product-resources {
  }

  .product-specifications,
  .product-returns-exchanges,
  .product-resources {
    padding: 2rem 0;

    border-bottom: 0.1rem solid $WTCGray2;
    .content {
      display: none;
      padding: 1rem 0 0;

      p {
        margin: 1rem 0;
      }
    }
  }

  .product-resources {
    // PDF Links
    a {
      font-size: 1.6rem;
      line-height: 2.5rem;
      color: $WTCBlack;
      display: inline-block;
      width: 100%;
      margin: 1rem 0;
      text-decoration: underline;

      &:before {
        content: '';
        display: inline-block;
        background: url(../images/PDF-icon.svg) center center no-repeat;
        background-size: contain;
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 1rem;
        position: relative;
        top: 0.3rem;
      }
    }
  }

  .block.upsell, .block.related {
    clear:both;
    width:100%;
    margin-top:2.5rem;
    margin-bottom: 3rem;

    .block-title {
      padding: 2rem 0;
      position: relative;
      width: 100%;
      margin-bottom: 4rem;

      p {
        background: $PureWhite;
        font-size: 2.2rem;
        line-height: 2.8rem;
        color: $WTCBlue;
        font-weight: 300;
        margin: 0;
        position: relative;
        text-align: center;
      }

      .upsell-border-wrapper, .products.wrapper {
          border: 0.1rem solid $WTCBlue;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          height: 100%;
          width: 10rem;
          height: 6rem;
      }
    }

    .block-instructions {
      margin-bottom: 2rem;
      img {
        display: block;
        margin: auto;
      }

      p {
        color: $WTCBlue;
        text-align: center;
        width: 100%;
      }
    }

    .block-content {
      ol {
        padding: 0 4rem;

        li {
          padding: 0 2rem;
        }

        .product-item-photo {
          margin-bottom: 1.5rem;
          display: block;
          img {
            width: 100%;
            height: auto;
          }
        }

        a.towishlist,
        .product-item-name {
          font-size: 1.7rem;
          line-height: 2.4rem;
          color: $WTCBlue;
          display: block;
        }

        .product-item-name {
          margin-bottom: 0.5rem;
        }

        a.towishlist {
          text-decoration: underline;
          margin-bottom: 2rem;
        }

        button.tocart {
          width: 100%;
        }

        .price-final_price {
          font-size: 2.2rem;
          line-height: 2.8rem;
          color: $WTCBlue;
          font-family: $Muli;
          font-weight: 300;
          margin-bottom: 1rem;
        }

        .product-description {
          font-weight: normal;
          color: $WTCGray1;
          font-family: $Muli;
          margin-bottom: 1.5rem;
        }
      }

      .slick-initialized .slick-slide {
        display: inline-block;
        vertical-align: top;
        float:none;
      }

      .slick-arrow {
        width: 2.6rem;
        height: 2.6rem;
        position: absolute;
        top: 50%;
        z-index: 5;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);

        span {
          display: none;
        }
      }

      .slick-prev {
        background: url(../images/mobile/arrow-icon-left.svg) center center no-repeat;
        background-size: contain;
        left: 1.5rem;
      }

      .slick-next {
        background: url(../images/mobile/arrow-icon-right.svg) center center no-repeat;
        background-size: contain;
        right: 1.5rem;
      }
    }
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 768px) {

}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .catalog-product-view {
    .column.main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;

      .container {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
      }

      .page.messages {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
      }

      .page-title-wrapper {
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
      }

      .product.media {
        margin-top: 0;
        -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
                order: 4;
      }

      .product-info-main {
        -webkit-box-ordinal-group: 6;
            -ms-flex-order: 5;
                order: 5;

        .login-cta {
          &:after {
            content: '';
            display: block;
            width: 100%;
            position: absolute;
            top: -3.5rem;
            left: 0;
            border-top: 0.1rem solid #898C8D;
          }
        }
      }

      .product.description {
        ul{
          list-style:inherit;
          padding-left:30px;
        }
        -webkit-box-ordinal-group: 7;
            -ms-flex-order: 6;
                order: 6;
      }

      .product-returns-exchanges {
        -webkit-box-ordinal-group: 8;
            -ms-flex-order: 7;
                order: 7;
      }

      .product-specifications {
        -webkit-box-ordinal-group: 9;
            -ms-flex-order: 8;
                order: 8;
      }

      .product-resources {
        -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
                order: 9;
      }

      .block.upsell, .block.related {
        -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
                order: 10;

        .block-instructions {
          display: none;
        }

        .slick-track {
          width: 100% !important;
        }

        ol {
          padding: 0;

          li {
            width: 45% !important;
            padding: 0;
            float: left;
            margin: 0 0 3rem;

            .product-item-info {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .product-item-photo {
                @include make-md-column(5, 0);
              }

              .product-item-details {
                @include make-md-column(6, 0);
              }
            }
          }
        }
      }
    }

    .page-title-wrapper,
    .block.upsell, .block-related {
      @include make-md-column(12, 0);
    }

    .product.media,
    .product.description,
    .product-specifications,
    .product-returns-exchanges,
    .product-resources {
      @include make-md-column(7, 0);
    }

    .product-info-main {
      @include make-md-column(4, 0);
    }
  }
}

/*** EXTRA LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 1200px) {

}
