.customer-account-create {
  .page.messages {
    display: block;
  }

  h1.page-title {
    margin-bottom: 0;
  }

  .create-account-instructions-wrapper {
    margin-bottom: 4rem;
  }

  fieldset {
    margin-bottom: 2rem;

    &:first-of-type {
      margin-top: 2rem;
    }
  }

  .page-wrapper {
    .page-main {
      margin-bottom: 2rem;
      .columns {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .field {
    margin-bottom: 1rem;

    &.search {
      margin: 0;
    }
  }

  .field.newsletter {
    input[type="checkbox"] {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  .actions-toolbar {
    button.primary {
      @include btn-primary();
      @include btn-large();
      width: 100%;
      margin-bottom: 1rem;
    }

    a.back {
      @include btn();
      @include btn-black();
      @include btn-a-large();
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .global-promotional-banner {
    padding: 0 2rem;
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 76.8rem) {
  .customer-account-create {
    .actions-toolbar {
      .primary,
      .secondary {
        button,
        a.back {
          display: inline-block;
          width: auto;
        }
      }
    }
  }
}
