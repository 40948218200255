//Font Family Mixins
@mixin font-dosis() {
  font-family: 'Dosis', sans-serif;
}

@mixin font-open-sans() {
  font-family: 'Open Sans', sans-serif;
}

// Glyphicon Font
@mixin glyphicon() {
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

// Button Type Mixins
@mixin btn() {
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
  font-family: $Muli;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: $PureWhite;
  padding: 1rem 2rem;
  border-radius: 7.6rem;
  border: none;
  box-shadow: none;
  text-align: center;
  display: block;

  background: $WTCBlue;

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $PureWhite;
    background: $WTCDarkBlue;
    border-color: $WTCDarkBlue;
  }
}

@mixin btn-a-large() {
  padding: 1.25rem 2.6rem;
}

@mixin btn-small() {
  height: 3.6rem;
}

@mixin btn-large() {
  height: 4.4rem;
}

@mixin btn-primary() {
  background: $WTCBlue;

  &:hover,
  &:focus,
  &:active {
      background: $WTCDarkBlue;
  }
}

@mixin btn-secondary() {
  background: $WTCYellow;

  &:hover,
  &:focus,
  &:active {
      background: $WTCYellow;
  }
}

@mixin btn-black() {
  background: $WTCBlack;
  color: $PureWhite;
  border: 0.2rem solid $WTCBlack;

  &:hover,
  &:focus,
  &:active {
    border-color: $WTCBlack;
    background: $PureWhite;
    color: $WTCBlack;
  }
}

@mixin btn-white() {
  font-weight: bold;
  background: $PureWhite;
  color: $WTCBlue;
  border-color: $PureWhite;

  &:hover,
  &:active,
  &:focus {
    background: $WTCDarkestBlue;
    color: $PureWhite;
    border-color: $PureWhite;
  }
}

// Grid system mixins

// Allows Bootstrap grid to be used without extend
// Modified to remove floats

// Centered container element
@mixin container-fixed($gutter: $grid-gutter-width) {
  margin-right: auto;
  margin-left: auto;
  padding-left:  floor(($gutter / 2));
  padding-right: ceil(($gutter / 2));
  @include clearfix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  margin-left:  ceil(($gutter / -2));
  margin-right: floor(($gutter / -2));
  @include clearfix;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  //float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 0.1rem;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
  margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-push($columns) {
  left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-pull($columns) {
  right: percentage(($columns / $grid-columns));
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 0.1rem;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-sm-min) {
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 0.1rem;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-md-min) {
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 0.1rem;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-lg-min) {
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}
