
/* NOTE: Currently a REM is set to 10px; (I.E. font-size: 1rem = 10px) */

/* --- BEGIN BASIC STYLES --- */

html {
    font-size: 62.5%;
}

body {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: $WTCBlack;
  font-family: $Muli;
  background-color: #EBEDF0;
}

.page-wrapper {
  background-color: $PureWhite;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.main-content {
  padding-top: 9.4rem;
}

.logo {
  img {
    width: 100%;
    height: auto;
    max-width: 20rem;
  }
}
img{
  max-width: 100%;
  height: auto;
}
ul {
  padding: 0;
  list-style: none;

  li {
    margin: 0;
  }
}

// Loading Mask
.loading-mask {
	display: none;
	position: fixed !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 5;
	background: rgba(0,0,0, 0.5);

	.loader {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 6;
	}
}

/* --- FONT STYLES --- */

p {
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: $WTCGray1;
  font-family: $Muli;
}

a {
  color: $WTCGray1;
	font-family: $Muli;
  font-size: 1.6rem;
  line-height: 2.5rem;

  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;

  &:hover,
  &:focus,
  &:active {
    color: $WTCDarkBlue;
    cursor: pointer;
  }
}

h1 {
  font-family: $Muli;
  font-size: 3rem;
  line-height: 3.8rem;
  font-weight: 300;
}

h2 {
  font-family: $Muli;
  font-size: 3rem;
  line-height: 4.1rem;
}

h3 {
  font-family: $Muli;
  font-size: 2.2rem;
  line-height: 2.8rem;
}

h4 {
  font-family: $Muli;
  font-size: 1.8rem;
  line-height: 2.3rem;
}

h5 {
  font-family: $Muli;
  font-size: 1.7rem;
  line-height: 2.1rem;
}

h6 {
  font-family: $Muli;
  font-size: 1.3rem;
  line-height: 1.9rem;
}

/* --- BUTTON STYLES --- */

button,
.btn {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;

  font-family: $Muli;
  font-size: 1.5rem;
  line-height: 1.9rem;
  border-radius: 7.6rem;
  padding: 1rem 3rem;

  color: $PureWhite;
  background: $WTCBlue;
  border: 0.2rem solid $WTCBlue;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;



  &:active,
  &:hover,
  &:focus {
    color: $PureWhite;
    background: $WTCDarkBlue;
    border-color: $WTCDarkBlue;
    outline:0;
  }

  &.btn-med {
    font-size: 1.2rem;
    line-height: 1.3rem;
    border-radius: 3.6rem;
    padding: 1rem 3rem;
  }
}

.btn-black {
  font-weight: bold;
  background: $WTCBlack;
  color: $PureWhite;

  &:hover {
    	background: -webkit-gradient(linear, left top, left bottom, from(#EE2831), to(#D3242B));
    	background: linear-gradient(180deg, #EE2831 0%, #D3242B 100%);
  }

  &:focus,
  &:active {
    	background: -webkit-gradient(linear, left top, left bottom, from(#D4232B), to(#C1232A));
    	background: linear-gradient(180deg, #D4232B 0%, #C1232A 100%);
  }
}

.btn-white {
  font-weight: bold;
  background: $PureWhite;
  color: $WTCBlue;
  border-color: $PureWhite;

  &:hover,
  &:active,
  &:focus {
    background: $WTCDarkestBlue;
    color: $PureWhite;
    border-color: $PureWhite;
  }
}

.btn-blue {
  font-weight: bold;
  background: $PureWhite;
  border: 0.1rem solid $WTCNeonBlue;
  color: $WTCNeonBlue;

  &:hover {
    background: -webkit-gradient(linear, left top, left bottom, from($WTCNeonBlue), to(#1165C1));
    background: linear-gradient(180deg, $WTCNeonBlue 0%, #1165C1 100%);
    color: $PureWhite;
  }

  &:active,
  &:focus {
    background: -webkit-gradient(linear, left top, left bottom, from(#1164C1), to(#125CAF));
    background: linear-gradient(180deg, #1164C1 0%, #125CAF 100%);
    color: $PureWhite;
  }
}

a.towishlist,
a.mailto {
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

/* --- FORM STYLES --- */

label,
label.label,
.label {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: normal;
  color: $WTCBlack;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

legend {
  margin-bottom: 0;
}

.type-select-radio{
  margin-bottom:8px;
  > label{
    font-weight: bold;
  }
  .field.choice{
    input{
      display: inline-block;
      margin-right:8px;
    }
  }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
select,
textarea {
  border-radius: 0.6rem;
  background-color: #EBEDF0;
  padding: 0 1.5rem;
  width: 100%;
  height: 4rem;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
          outline: none;
          font-family: $Muli;
          color: $WTCBlack;

          &.mage-error {
            border: 0.1rem solid $WTCBlue;
          }
}

select {
  padding-right: 3rem;
  background: $PureWhite;
  border: 0.1rem solid #CCCCCC;
}

select::-ms-expand {
    display: none;
}

.select-arrow-wrapper {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left:0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    border-top: 0.4rem solid $WTCBlack;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 2.6rem;
    height: 100%;
    width: 0;
    border-right: 0.1rem solid #CCCCCC;
  }
}

div.mage-error {
  color: $WTCBlack;
}

textarea {
  height: 12rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.password-strength-meter,
#password-error {
  font-size: 1rem;
}

.password-strength-meter-0,
.password-strength-meter {
  color: red;
}

.password-strength-meter-1,
.password-strength-meter-2,
.password-strength-meter-1 > div,
.password-strength-meter-2 > div {
  color: orange;
}

.password-strength-meter-3,
.password-strength-meter-3 > div {
  color: green;
}

/* --- BEGIN MODAL STYLES --- */

.modal-popup {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 900;

  &._inner-scroll {
    overflow-y: visible;
  }

  .modal-inner-wrap {
      width: 90%;
      max-width: 60rem;
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);

      .modal-content {
        border-radius: 0;
        text-align: center;
        font-size: 1.8rem;
        line-height: 1.33;
        font-family: 'PT Sans', sans-serif;
        font-weight: bold;
        margin: 0 auto;
        padding: 0 15%;

        > div {
          padding-top: 1rem;
        }
      }

      .modal-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
                padding-bottom: 4rem;
        button.action-primary {
          @include btn-primary();
        }

        button {
          margin: 0 0.5rem;
        }
      }
  }
}

.modals-wrapper {
  display: block;
}

.modal-popup {
  background: rgba(0,0,0,.5);
}

article, aside, details, figcaption, figure, main, footer, header, nav, section, summary {
    display: block;
}

.modal-slide._inner-scroll .modal-inner-wrap {
  max-height: 90%;
  overflow-y: visible;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;

  background-color: #fff;
  background-clip: padding-box;
  border: 0.1rem solid #999;
  border: 0.1rem solid rgba(0,0,0,.2);
  border-radius: 0.6rem;
  outline: 0;
  -webkit-box-shadow: 0 0.3rem 0.9rem rgba(0,0,0,.5);
          box-shadow: 0 0.3rem 0.9rem rgba(0,0,0,.5);

  top: 50%;
  position: relative;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.modal-slide._show, .modal-popup._show {
  visibility: visible;
}

.modal-popup._inner-scroll {
  overflow-y: visible;
}

.modal-popup .modal-header, .modal-popup .modal-content, .modal-popup .modal-footer {
  padding-left: 3rem;
  padding-right: 3rem;
  background: white;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.modal-popup .modal-header {
  padding-bottom: 1.2rem;
  padding-top: 3rem;
}

.modal-popup .modal-content {
  overflow-y: auto;
}

.modal-popup .modal-title {
  border-bottom: 0.1rem solid #c1c1c1;
  font-weight: 300;
  padding-bottom: 1rem;
  font-size: 2.6rem;
  margin-bottom: 0;
  min-height: 1rem;
  word-wrap: break-word;
}

.modals-overlay {
  display: none;

  background-color: rgba(51, 51, 51, 0.55);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modal-header {
  padding: 0;

  .action-close {
    display: inline-block;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    text-shadow: none;
    font-weight: 400;
    -webkit-box-shadow: none;
            box-shadow: none;
    padding: 2.1rem 2.5rem;
    z-index: 100;
    outline: none;
    @include glyphicon-remove;

    &:before {
      -webkit-font-smoothing: antialiased;
      font-size: 2rem;
      line-height: 2rem;
      color: #8f8f8f;
      @include glyphicon();
      margin: 0.3rem 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
    }

    span {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      &:before {
        color: $WTCBlue;
      }
    }
  }
}

.modal.in {
  .modal-header {
    padding: 1.5rem;
  }

  .modal-dialog {
   margin: 10% auto;
 }
}

/* --- RATING & REVIEWS --- */

.rating-summary {
  .label {
    display: none;
  }

  .rating-result {
    width: 8.8rem;
    position: relative;
    top: -0.3rem;
    vertical-align: middle;
    display: inline-block;

    span {
      display: none;
    }

    &:before {
      left: 0;
      position: relative;
      top: 0;
      width: 100%;
      z-index: 1;
      -webkit-font-smoothing: antialiased;
      color: $WTCNeonBlue;
      @include glyphicon();
      font-size: 1.4rem;
      height: 1.4rem;
      letter-spacing: 0.2rem;
      line-height: 1;
      content: '\e006' '\e006' '\e006' '\e006' '\e006';
      display: block;
      font-style: normal;
      font-weight: normal;
      speak: none;
    }
  }

  .number-of-reviews {
    display: inline-block;

    span {
      text-transform: uppercase;
      color: $WTCGray1;
      @include font-open-sans();
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: normal;
    }
  }
}

.reviews-actions {
  display: block;

  a {
    font-size: 1.2rem;
    line-height: 1;

    &:first-of-type {
      padding-right: 1rem;
    }

    &:nth-child(2) {
      padding-left: 1rem;
      border-left: 0.1rem solid $WTCGray1;
    }
  }
}

/* --- PAGE MESSAGING --- */

.page.messages {
  width: 100%;
  font-family: $Muli;

  h3{
    color: #000;
  }

  .unapproved-cart{
    cursor: pointer;
  }

  .messages:last-of-type {
      margin-bottom: 3rem;
      display: block;

      &:empty {
        margin-bottom: 0;
      }

      .message {
        display: none;

        &:last-of-type {
          display: block;
        }
      }
  }

  .messages {
    .message {
      display: none;

      &:last-of-type {
        display: block;
      }
    }
  }

  .message {
    padding: 1rem 2rem;
    color: $PureWhite;
    margin: 3rem 0;
    background: $WTCBlue;
    border-radius: 0.3rem;

    & > div {
      max-width: 120rem;
      display: block;
      margin: auto;
      position: relative;
      padding-left: 2.4rem;
      &:before {
        @include glyphicon();
        font-size: 1.4rem;
        color: $PureWhite;
        display: inline-block;
        position: absolute;
        top: 0.7rem;
        margin-right:0.3rem;
        left: 0;
      }
    }

    &.message-success,
    &.success {
      &>div {
        &:before {
          content: "\e089";
        }
      }
    }

    &.message-notice,
    &.notice,
    &.message-error,
    &.error  {
      &>div {
        &:before {
          content: "\e101";
        }
      }
    }

    a {
      color: $WTCNeonBlue;
      text-decoration: underline;
    }
  }
}

/*** MEDIUM SCREEN STYLES  ***/

@media only screen and (min-width : 76.8rem) {

  .page-wrapper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    min-height: 100%;
    position: relative;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
  }

  .navigation, .header.panel, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 120rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }

  .page-main {
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
        padding: 2rem;
  }

  .modal-popup.modal-slide .modal-footer {
  border-top: 0.1rem solid #c1c1c1;
  text-align: right;
  }

  .opac-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(35,31,32,0.5);
    z-index: 1;
  }
}


@media only screen and (min-width : 768px) {
  .container {
    width: 100%;
  }
}


/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {

  .page-header {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 120rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }

  .page.messages {
    padding: 0;
  }

  .main-content {
    padding-top: 11.2rem;
  }

  /* --- FONT STYLES [LARGE SCREEN] --- */
  h1 {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }

  h2 {
    font-size: 3.3rem;
    line-height: 4.1rem;
  }

  h3 {
    font-size: 2.6rem;
    line-height: 3.3rem;
  }

  h4 {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

/*** EXTRA LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 1200px) {
  .container {
    width: 117rem;
  }
}
