.wordpress-post-view {
  .main {
    padding: 0;
    max-width: 75rem;
  }
  .post-entry {
    .post-image {
      display: block;
      width: 100vw;
      height: 50vw;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .post-social-links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      clear: both;

      li {
        margin: 1.5rem;
        a {
          width: 5.2rem;
          height: 5.2rem;
          border: 0.1rem solid $WTCBlue;
          color: $WTCBlue;
          border-radius: 50%;
          position: relative;
          display: block;

          &:hover {
            background: $WTCBlue;
            color: $PureWhite;

            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
          }

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .page-title-wrapper,
    .post-content {
      width: 100%;
      display: inline-block;
      padding: 0 1.5rem;
      margin: 1.5rem 0;

      h1 {
        text-align: center;
        color: $WTCBlack;
      }

      img {
        width: auto;
        height: auto;
        margin: 1.5rem 0;
      }
    }

    .post-meta-item {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 1.7rem;
      line-height: 2.4rem;
      color: $WTCBlack;

      a {
        display: inline-block;
        font-size: 1.7rem;
        line-height: 2.4rem;
        color: $WTCBlack;
      }
    }
  }
}



@media only screen and (min-width : 768px) {
  .wordpress-post-view {
    .main {
      display: block;
      margin: auto;
      float: none;
    }
    .post-entry {
      .post-image {
        display: block;
        width: 100%;
        height: 42rem;
      }

      .post-meta-item {
        width: 50%;
        display: block;
        float: left;
        text-align: center;
        font-size: 1.7rem;
        line-height: 2.4rem;
        color: $WTCBlack;

        &.post-user {
          padding-right: 1.5rem;
          text-align: right;

          border-right: 0.1rem solid $WTCBlack;
        }

        &.post-date {
          padding-left: 1.5rem;
          text-align: left;
        }

        a {
          font-size: 1.7rem;
          line-height: 2.4rem;
          color: $WTCBlack;
        }
      }
    }
  }
}

@media only screen and (min-width : 992px) {
  .wordpress-post-view {
    .main {
      @include make-md-column(12, 0);
    }
  }
}
