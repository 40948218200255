// Warner Tech Care Color Palette
$WTCDarkestBlue:   #263342;
$WTCDarkBlue:      #006AA7;
$WTCBlue:          #007CC3;
$WTCNeonBlue:      #00A2FF;
$WTCYellow:        #EACB72;
$WTCLightGray:     #C8C8C8;
$WTCGray1:         #808080;
$WTCGray2:         #898C8D;
$WTCBlack:         #222222;

$OffWhite:         #ECEEF0;
$PureWhite:        #FFFFFF;

//Common Variables
$vh: 100vh;
$fullWidth: 100%;

// Warner Tech Care Fonts
@import url('https://fonts.googleapis.com/css?family=Muli:200,300,400,600,700');

$Muli: 'Muli', sans-serif;
