.promotion-banner {
  margin-bottom: 5rem;
  .col-md-12 {
    background: $WTCBlue;
    color: $PureWhite;
    padding: 5rem 4rem;

    h3 {
      margin-top: 0;
    }

    p {
      color: $PureWhite;
    }
  }

  //.col-md-5 {
  //  position: relative;
  //  height: 50vw;
  //  max-height: 50rem;
  //  overflow: hidden;
  //  img {
  //    position: absolute;
  //    width: 100%;
  //    height: auto;
  //    top: 50%;
  //    left: 50%;
  //    -webkit-transform: translate(-50%, -50%);
  //            transform: translate(-50%, -50%);
  //  }
  //}
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 76.8rem) {

}


/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .promotion-banner {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    //.col-md-5 {
    //  height: auto;
    //
    //  img {
    //    height: 100%;
    //    width: auto;
    //  }
    //}
  }
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 1200px) {
  .promotion-banner {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
}
