.subcategory-block {
  margin: 4rem 0;
  h1 {
    text-align: center;
    margin: 2.5rem 0;

    a {
      font-family: "Muli", sans-serif;
      font-size: 3rem;
      line-height: 3.8rem;
      font-weight: 300;
      text-align: center;
      color: $WTCDarkestBlue;
    }
  }

  .subcategory-image {
    @include make-xs-column(12, 0);

    img {
      display: block;
      margin: auto;
      width: 100%;
      height: auto;
      max-width: 50rem;
    }
  }

  .subcategory-content {
    @include make-xs-column(12, 0);

    ul {
      li {
        @include make-xs-column(12, 0);
        margin: 1rem 0;

        a {
          text-decoration: underline;
          color: $WTCBlue;
        }
      }
    }
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 768px) {
  .subcategory-block {
    .subcategory-image {
      @include make-sm-column(4, 0);
      float: left;
    }

    h1 {
      text-align: left;
      margin-top: 0;
      a {
        text-align: left;
        font-size: 3.8rem;
        line-height: 4.8rem;
      }
    }

    .subcategory-content {
      @include make-sm-column(8, 0);
      float: left;
      padding-left: 10%;

      ul {
        li {
          @include make-sm-column(6, 0);
          display: block;
          float: left;
        }
      }
    }
  }
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .subcategory-block {

  }
}
