.checkout-cart-index,
.customer-approve-view {

  .messages{
    margin: 0 0 3rem 0;
  }

  .main-content {
    padding-bottom: 4rem;
  }

  h1.page-title {
    color: $WTCBlue;
  }

  .cart-empty {
    a {
      color: $WTCBlue;
      text-decoration: underline;
    }
  }

  .cart-container {
      .item.current > .page {
        display: inline-block;
        padding: 5px 14px;
        color: white;
        background: #007CC3;
        border-radius: 15px;
        font-size: 1.6rem;
    line-height: 2.5rem;
      }
    .pager .previous > span, .pager .next > span,  {
        float: none !important;
    }

    .desktop-only {
      display: none;
    }
    .action-towishlist {
      color: $WTCGray1;

      &:hover,
      &:focus,
      &:active {
        color: $WTCBlue;
        text-decoration: none;
      }
    }

    .cart-summary {
      background: $WTCBlue;
      border-radius: 0.3rem;
      p {
        color: #fff;
      }
      label.label,
      legend.legend,
      th,
      td {
        color: $PureWhite;
        font-family: $Muli;
      }

      > .title {
        display: none;
      }

      > button.checkout {
        display: none;
      }

      button.checkout {
        width: 100%;
        font-size: 2.2rem;
        @include btn-large();
        @include btn-white();
      }
      > button.checkout {
        /*for the top checkout button*/
        margin: 2rem 2rem 3rem;
        width: 90%;
        display: none;
      }

      a.action.multicheckout {
          margin-top: 2rem;
          display: block;
          color: $PureWhite;
          font-family: $Muli;
      }

      input, select {
        background-color: #fff;
      }
      .field[name='shippingAddress.country_id'] .control,
      .field[name='shippingAddress.region_id'] .control {
          position: relative;
          margin-bottom: 1rem;
          &:before {
            content: '';
            display: block;
            position: absolute;
            right: 1rem;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left:0.4rem solid transparent;
            border-right: 0.4rem solid transparent;
            border-top: 0.4rem solid $WTCBlack;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 2.6rem;
            height: 100%;
            width: 0;
            border-right: 0.1rem solid #CCCCCC;
          }
        }

      legend {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-weight: bold;
        color: $WTCBlue;
        border-bottom: none;
        margin-bottom: 1rem;
      }

      dl.items.methods {
        width: 100%;
        list-style-type: none;
        dt, dd {
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: $PureWhite;
          margin: 0;
        }
        dt.item-title {
          text-align: left;
          position: relative;
        }
        dd.item-options {
          overflow:hidden;
          label {
            color: $PureWhite;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: bold;
            margin-left: 1rem;
          }

          input {
            float: left;
          }
        }
      }
      .block.shipping {
        #block-summary {
          padding: 2rem;
          border-top: 1px solid $WTCLightGray;
          -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24);
                  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24);
          border-radius: .1rem;
        }
        .fieldset.estimate {
          margin-bottom: 2rem;
          p, br {
            display: none;
          }
        }
        #co-shipping-method-form {
          border-top: 2px solid $WTCLightGray;
          margin: 0 -2rem;
          padding: 2rem 2rem 0 2rem;
          legend {
            margin-bottom: 1rem;
          }
        }
      }
      .cart-totals {
        padding: 2rem 2rem;
        border-top: 1px solid $WTCLightGray;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24);
                box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24);
        border-radius: .1rem;
        .table-wrapper {
          table {
            font-size: 1.6rem;
            line-height: 2.8rem;
            margin-bottom: 0;
            caption {
              display: none;
            }
            tr {
              border: none;
              th {
                padding: 0 1rem 1rem 0;
                font-weight: normal;
                border: none;
                background: none;
              }
              td {
                padding: 0 0 1rem 1rem;
                text-align: right;
                font-weight: normal;
                border: none;
              }
              span.label {
                font-weight: bold;
                text-transform: none;
                font-size: 1.6rem;
                color: $PureWhite;
              }
              &.totals.sub {
                th {
                  font-weight: bold;
                }
              }
              &.grand.totals {
                font-size: 1.8rem;
                line-height: 2.8rem;
                @include font-dosis;
                border-top: 0.2rem solid $PureWhite;

                th, td {
                  margin-top: 1rem;
                  padding: 2rem 0 0 0;
                }
              }
            }
          }
        }
      }
      .checkout-methods-items {
        padding: 2rem;
        border-top: 1px solid $WTCLightGray;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24);
                box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24);
        border-radius: .1rem;
          margin: 0 0 2rem;
      }
    }

  }
  .input-text.qty {
    text-align: center;
    max-width: 7rem;
  }
  .block {
    &.shipping {
      .title {
        padding: 1rem 0 1rem 0;
        strong{
          display: block;
          margin:2rem 1.5rem;
          padding:1rem 0.5rem;
          @include btn-white();
          border-radius: 7.6rem;
          text-transform: uppercase;
          @include font-open-sans;
          font-size: 1.5rem;
          line-height: 2rem;
          letter-spacing: 0.1rem;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    &.discount,
    &.giftcard,
    &.gift-item-block {
      margin: 1rem 0;
      padding: 2rem;
      border-bottom: 0.1rem solid $WTCDarkestBlue;
      @include clearfix;
      @include make-xs-column(12, 0);
      label {
        text-transform: none;
      }
      .title {
        position: relative;
        font-size: 1.4rem;
        outline: none;

        &:before {
          content: '';
          width: 2rem;
          height: 2rem;
          background: url(../images/accordian-down-arrow-icon.svg) center center no-repeat;
          position: absolute;
          right: 2rem;
          transition: all 0.2s linear;
        }
      }

      &.discount {
        .fieldset.coupon {
          @include clearfix;
          .field {
            width:100%;
            margin-top: 1rem;
            label {
              display: none;
            }
          }
          .actions-toolbar {
            width: 100%;
            button {
              padding: 1rem 2.6rem;
              width: auto;
              float: left;
              margin-top: 1rem;
            }
          }
        }
      }

      .content {
        padding: 0;

        .control {
          input {
            width: 100%;
          }
        }

        .choice {
          margin: 1rem 0;

          input[type="checkbox"] {
            display: inline-block;
            margin-right: 1rem;
          }
        }

        .action-update {
          @include btn-primary();
        }


        button.primary {
          background: $WTCBlue;

          &:hover {
            background: $WTCDarkBlue;
            color: $PureWhite;
          }
        }
      }

      &.active,
      &._active {
        .title {
          &:before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .checkout-methods-items {
   margin: 0;
   padding: 1.5rem;
   li {
    margin: 1rem 0;
    text-align: center;
  }
}

.actions-toolbar {
  width: 70%;
  float: right;
  a.action-delete {
    position: relative;
    display: inline-block;

    span {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      width: 2.3rem;
      height: 2.3rem;
      background: url(../images/trash-icon.svg) center center no-repeat;
    }
  }

  a.action-towishlist {
    float: left;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $WTCBlue;
    }
  }

  a.action-edit {
    margin: 0 2rem;
    display: inline-block;

    span {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      width: 2.3rem;
      height: 2.3rem;
      background: url(../images/edit-icon.svg) center center no-repeat;
    }

    &:hover,
    &:focus,
    &:active {
      color: $WTCBlue;
      text-decoration: none;
    }
  }
}

.main.actions {
  button.update,
  button.clear {
    @include btn();
  }

  button.clear,
  a.continue {
    display: none;
  }
}

form.form-cart {
  /*margin-bottom: 2rem;*/

  .table-wrapper {
    margin-bottom: 2rem;
  }

  .cart.main.actions {
    button {
      margin-bottom: 2rem;
    }
  }

}

.shopping-cart-item-overview-wrapper {
  padding: 2rem 0 4rem;
  border-bottom: 1px solid $WTCGray1;
  position: relative;

  img.product-image-photo {
    width: 100%;
    height: auto;
  }
}

.cart-gift-item {
  margin-bottom: 1rem;
}


.shopping-cart-item-overview-wrapper {
  width: 100%;
  display: inline-block;

  .shopping-cart-item-image-wrapper {
    button.approved {
      border: 0.1rem solid #e0e0e0;
      background: none;
      border-radius: 0.3rem;
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem;
      z-index: 5;

      .approved-checkmark {
        width: 2.2rem;
        height: 2.2rem;
        display: block;
        margin: auto;

        background: url(../images/approved-checkmark.svg) center center no-repeat;
        background-size: contain;
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
        border: 0.1rem solid #e0e0e0;
      }
    }
    a{
      display: inline-block;
    }
    img{
      max-width:100%;
      height:auto;
    }
    display: block;
    width: 25%;
    float: left;
    overflow: hidden;
    position: relative;
  }

  .product-item-details {
    display: block;
    width: 70%;
    float: left;
    margin-left: 5%;

    .product-item-name {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      display: block;
      color: $WTCBlue;
      font-family: $Muli;
    }

    .item-options {
      tr{
        td {
          color: $WTCBlack;
          @include font-open-sans();
          font-size: 1.6rem;
          line-height: 2.4rem;
          white-space: nowrap;

          &:first-of-type {
            padding-right: 1rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .cart.item.table {
    border-top: 1px solid $WTCLightGray;
    margin-top: 1.5rem;

    th {
      @include font-open-sans();
      font-size: 1.2rem;
      line-height: 2rem;
      color: $WTCBlack;
      font-weight: normal;
      padding: 0;
      border-bottom: none;
      padding-top: 0.5rem;
    }

    td {
      color: $WTCBlack;
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0;
      border: none;

      &.subtotal {
        font-weight: bold;
      }

      &.qty {
        input {
          width: 80%;
          margin: auto;
        }
      }
    }
  }
}
}


@media only screen and (min-width : 992px) {
  .checkout-cart-index,
  .customer-approve-view {
    .cart-container {
      .desktop-only {
        display: block;
      }

      .shopping-cart-item-overview-wrapper {
        .actions-toolbar {
          width: 100%;
          bottom: 1rem;
          .action-towishlist {
            float: left;
          }

          .action-edit,
          .action-delete {
            float: right;
          }
        }
      }

      form.form-cart {
        .action.update {
          float: right;
        }
      }

      .cart-summary {
        .cart-totals {
          margin: 0;
        }
        .block.shipping {
          .title {
            display: none !important;
            /*removes toggle button from desktop view*/
          }
          #block-summary {
            display: block !important;
            /*negates being hidden by "Estimate Shipping & Tax" button*/
            margin: 0;
            }
          }
          .checkout-methods-items {
            margin: 0;
          }

      }
    }
      .desktop-only {
        display: block;
      }
      .cart-items-header {
        font-size: 1.8rem;
        color: $WTCBlue;
        font-weight: bold;
        border-bottom: 0.1rem solid $WTCDarkestBlue;
        padding: 1rem 0;
        overflow: hidden;
        div {
          float: left;
        }
        .items-label {
          width: 53%;
        }
        .price-label {
          width: 15%;
        }
        .qty-label {
          width: 16%;
        }
        .total-label {
          width: 16%;
        }
      }
      .cart-container {
      /*@include make-row;*/
      .cart-summary {
        width: 33%;
        float: right !important;
        margin-bottom: 4rem;
      }
      .cart.main.actions {
        @include clearfix;
      }
      .cart-left {
        > form {
          @include clearfix;
        }
        .block.discount {

          .title {
            font-size: 1.5rem;
          }

          .field {
            width: 60%;
          }
          .fieldset.coupon {
            .actions-toolbar {
              width: 100%;
            }
          }
        }
        position: relative;
        width: 64%;
        padding-right: 4rem;
        float: left;
        .shopping-cart-item-overview-wrapper {
          clear: both;
          .shopping-cart-item-image-wrapper {
            float: left;
          }
          .product-item-details {
            float: right;
            .product-item-specs {
              .product-item-name {
                font-size: 1.6rem;
                line-height: 2.4rem;
                padding-right: 1.5rem;
              }
              font-size: 1.6rem;
              line-height: 2.4rem;
              float: left;
              width: 33%
            }
          }
          .cart.item.data.table {
            float: right;
            width: 67%;
            border: 0;
            margin-top: 0;
            thead {
              display: none;
            }
            tbody {
            }
          }
          .actions-toolbar {
            position: absolute;
            right: 0;
            bottom: 1rem;
          }
        }
      }
    }

    .global-promotional-banner {
      padding: 0;
    }
  }

  .customer-approve-view{
    .cart-container{
      .cart-left{
        width:100%;
        float:none;
      }
      .cart-items-header{
        .approve-select{
          width:10%;
        }
        .items-label{
            width:43%;
        }
      }
      .shopping-cart-item-overview-wrapper{
        .product-select{
          width:10%;
          float:left;
        }
        .shopping-cart-item-image-wrapper{
          width:15%;
        }
      }
      .actions{
        padding-top:15px;
        padding-bottom:15px;
      }
    }
  }
}


@media only screen and (min-width : 1200px) {
  .checkout-cart-index,
  .customer-approve-view {
    .cart-container {
      .cart-left {
        .block.discount {
          .title {
            font-size: 1.8rem;
          }
        }
      }

      .cart.main.actions {
        .action.continue {
          font-size: 1.6rem;

          &:before {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
