.checkout-onepage-success {
  .checkout-success {
    margin-bottom: 3rem;
  }

  h1 {
    text-align: center;
    margin-bottom: 0;
  }

  p {
    text-align: center;

    a {
      color: $WTCBlue;
    }
  }

  .actions-toolbar {
    a.btn {
      display: block;
      margin: 2rem auto 0;
      max-width: 20rem;
      color: $PureWhite;
    }
  }
}
