.primary-category-block {
  h1 {
    text-align: center;
  }
  .categories {
    margin-bottom: 4rem;
    overflow: hidden;
  }

  h3 {
    font-weight: 300;
    color: $WTCBlue;
    padding: 1.4rem 0;
    border-bottom: 0.1rem solid $WTCBlack;
    width: 100%;
    position: relative;
    margin: 0;

    a {
      color: $WTCBlue;
      font-size: 2.2rem;
      line-height: 2.8rem;
      padding-right: 2.4rem;
    }
  }

  .parent {
    &.subs{
      &:after {
        content: '';
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.2s;
        transition: all 0.2s;

        background: url(../images/accordian-down-arrow-icon.svg) center center no-repeat;
        background-size: contain;
      }
    }
    &.active {
      &:after {
        -webkit-transform: rotate(180deg) translateY(50%);
                transform: rotate(180deg) translateY(50%);
      }
    }
  }


  .subcategory-content {
    display: none;

    p {
      margin: 0;
    }

    a.btn {
      max-width: 100%;
      white-space: pre-line;
    }
  }
  .subcats{
    display: none;
    ul {
      padding: 2rem 1.5rem 0;
      li {
        a {
          display: block;
          margin: 0.5rem 0;
          color: $WTCBlue;
          text-decoration: underline;
        }

        &.view-all {
          a {
            display: inline-block;
            width: auto;
            color: $PureWhite;
            text-decoration: none;
          }
        }
      }
      li:nth-child(odd){
        padding-right:1.5rem;
      }
      li:nth-child(even){
        padding-left:1.5rem;
      }
      li.view-all{
        margin-top:1.2rem;
        padding:0;
      }
    }
  }
  li.sub-cat-link{
    display: inline-block;
    width:49%;
    vertical-align: top;
    a{
      display: block;
    }
  }

  .actions{
    display:none;
    margin-top:1.2rem;
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 768px) {
  .primary-category-block {
    img {
      max-width: 100%;
      height: auto;
    }

    h3 {
      border: none;

      &:after {
        display: none;
      }
    }

    h3,
    .subcategory-content {
      display: block;
      width: 100%;
      float: right;
    }
  }
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .primary-category-block {
    .categories {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      .actions{
          display: block;
      }
      .category-wrapper {
        .subcats{
          display: block;
        }
        margin:0 0 12px 0;
        h3 a {
          color: $WTCBlue;
          font-size: 2.6rem;
          line-height: 3.3rem;
        }

        ul {
          display: block;
          li {
            padding: 0;
            a {
              margin: 0;
            }
          }
          li:nth-child(odd){
            padding-right:1.5rem;
          }
          li:nth-child(even){
            padding-left:1.5rem;
          }
          li.view-all{
            margin-top:1.2rem;
            padding:0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width : 992px) {
  .primary-category-block {
    h1 {
      text-align: center;
    }
    .category-wrapper{
      padding:0;
    }
  }
}
