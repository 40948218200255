// Theme fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Dosis:400,800');

// Bootstrap
@import 'bootstrap';

// Variables
@import 'variables';

// Mixins
@import 'mixins';

// Theme base styles
@import 'base';

// Modules
@import 'modules/account-dashboard';
@import 'modules/account-login';
@import 'modules/account-create';
@import 'modules/shopping-cart';
@import 'modules/checkout';
@import 'modules/checkout-success';
@import 'modules/checkout-multishipping-address';
@import 'modules/homepage';
@import 'modules/contact';
@import 'modules/catalog-product-view';
@import 'modules/catalog-product-list';
@import 'modules/basic-page';

// Components
@import 'components/header';
@import 'components/footer';
@import 'components/breadcrumbs';
@import 'components/modal';

//Blocks
@import 'blocks/primary-category';
@import 'blocks/subcategory';
@import 'blocks/promotion-banner';

//Wordpress FishPig
@import 'wordpress/article';
@import 'wordpress/articles-list';
