.customer-account-index,
.customer-order-review,
.customer-approve-review,
.customer-account-edit,
.customer-account-forgotpassword,
.customer-address-form,
.sales-order-history,
.wishlist-index-index,
.wishlist-index-share,
.downloadable-customer-products,
.vault-cards-listaction,
.magento-advancedcheckout-sku-index,
.magento-customerbalance-info-index,
.magento-giftcardaccount-customer-index,
.newsletter-manage-index,
.paypal-billing-agreement-index,
.magento-giftregistry-index-index,
.review-customer-index,
.magento-reward-customer-info,
.magento-invitation-index-index,
.customer-account-logoutsuccess,
.customer-account-create,
.customer-approve-view,
.sales-order-view,
.customer-address-index,
.account  {
  .items.addresses {
    address, .actions{
      margin: 0 0 10px;
    }
  }
  .main-content {
    padding-top: 13.2rem;
    .sidebar-one {
      position: fixed;
      z-index: 5;
      width: 100%;
      background: $OffWhite;
      top: 9.4rem;
      left: 0;
      overflow-y: auto;
      -webkit-transition: all 0.5s ease-out 0s;
      transition: all 0.5s ease-out 0s;

      &.active {
        bottom: 0;
      }

      .block.account-nav {
        margin-bottom: 0;
      }

      .title {
        padding: 1rem 2rem;
        font-family: $Muli;;
        cursor: pointer;
        border-bottom: 0.1rem solid $WTCGray1;
        display: block;
        text-decoration: none;
        font-weight: 700;
        line-height: 1.1;
        font-size: 1.6rem;
        position: relative;

        &:before {
          content: '';
          width: 1.6rem;
          height: 1.6rem;
          position: absolute;
          right: 2rem;
          top: 1rem;

          background: url(../images/mobile/accordian-down-arrow-icon.svg) center center no-repeat;
          background-size: contain;
          transition: all 0.2s linear;
        }

        &.active {
          &:before {
            transform: rotate(180deg);
          }
        }
      }

      ul.nav {
        padding: 2rem 0;
        display: none;
        margin: 0;

        li.item {
          padding: 1rem 2rem;
          font-size: 1.6rem;

          &.current {
            padding-left: 1.6rem;
            border-left: 0.4rem solid $WTCBlue;
          }

          a {
            padding: 0;
            font-size: 1.6rem;
            line-height: 1.1;
            color: $WTCBlack;
          }
        }
      }
    }

    .sidebar-2 {
      margin-top: 3rem;

      .block-wishlist {
        .added-to-cart-remove-from-wishlist-wrapper,
        .remove-from-wishlist-wrapper {
          display: none;
        }

        .product-items {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
          -webkit-box-align: stretch;
              -ms-flex-align: stretch;
                  align-items: stretch;

          li {
            width: 50%;
            margin-bottom: 2rem;
          }
        }
      }
    }

    .page-title {
      display: inline-block;
    }

    span.order-status {
      border: 0.2rem solid #ccc;
      border-radius: 0.3rem;
      display: inline-block;
      margin: 0.3rem 0 0 1rem;
      padding: 0.2rem 1rem;
      text-transform: uppercase;
      vertical-align: middle;
      position: relative;
      top: -1rem;
    }

    .order-date {
      display: block;
      margin: 2rem 0 0;
    }

    .actions {
      .order {
        margin-right: 2rem;
      }
    }

    .order-details-items {
      margin-top: 2rem;
      .order-title {
        display: none;
      }
    }

    .box-upload > fieldset > div > div > button {
      /* sets buttons on order by SKU page, and other upload areas, to white */
      margin: 1rem 0;
      @include btn();
      @include btn-white();
      @include btn-small();
    }

    .block-title {
      border-bottom: 0.1rem solid #c6c6c6;
      margin-bottom: 2.5rem;
      padding-bottom: 1rem;
      font-size: 2.2rem;
      line-height: 1.25;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      font-family: $Muli;
      .counter {
        font-size: 1.4rem;
        font-style: italic;
        color: $WTCGray1;
      }
    }

    .items.order-links {
      display: none;
    }

    .order-details-items.ordered {
      caption.table-caption {
        display: none;
      }
    }

    .block-dashboard-orders .block-content , .orders-history {
        overflow-x: auto;
    }

    #my-orders-table {
      .items-qty {
        margin: 0;

        span.title {
          display: none;
        }
      }

      .table-caption {
        display: none;
      }

      td.actions {
        a.view  {
          margin-right: 2rem;
        }
      }

      td.price {
        font-weight: bold;
      }

      .item-options {
        dt,
        dd {
          display: block;
          width: 100%;
          float: left;
          margin-left: 0;
        }

        dd {
          margin-bottom: 1rem;
        }

        dt {
          font-weight: bold;
        }
      }
    }

    .toolbar-amount {
      font-weight: bold;
    }

    .order-products-toolbar {
      .limiter {
        display: none;
      }
    }

    .block, .customer-review .product-details, .block-banners, .block-banners-inline {
      margin-bottom: 4rem;

      .items.addresses {
        padding-left: 0;
        list-style: none;
      }
    }

    .block-addresses-list .items.addresses .item.actions .action:after, .account .data.table .col.actions .action:after, [class^='sales-guest-'] .data.table .col.actions .action:after, .sales-guest-view .data.table .col.actions .action:after, .block .box-actions .action:after {
      border-left: 0.1rem solid #a6a6a6;
      content: '';
      display: inline-block;
      height: 1.2rem;
      margin: 0 1rem;
      vertical-align: -0.1rem;
    }

    .block-addresses-list .items.addresses .item.actions .action:last-child:after,
    .account .data.table .col.actions .action:last-child:after,
    [class^='sales-guest-'] .data.table .col.actions .action:last-child:after,
    .sales-guest-view .data.table .col.actions .action:last-child:after,
    .block .box-actions .action:last-child:after {
      display: none;
    }

    .block:not(.widget) {
      .block-title > .action {
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2rem;
        width: 100%;
        display: block;
      }

      .block-content .box {
        margin-bottom: 2rem;
      }

      .block-content .box:last-child {
        margin-bottom: 0;
      }
    }

    .columns > div.column.main > div.block.block-reward-info > div.block-title > a {
      float: none;
      /*fixes issue on Reward Points page where "Learn More" overlaps the hr line on some screen sizes*/
    }

    .fieldset, .fieldset > .field, .fieldset > .fields > .field {
      margin: 0 0 1rem;
    }

    .fieldset > .field.choice .label, .fieldset > .fields > .field.choice .label {
      display: inline;
      font-weight: normal;
      margin: 0;
    }

    .fieldset > .legend {
      margin: 0 0 2rem;
      padding: 0 0 1rem;
      width: 100%;
      box-sizing: border-box;
      float: left;
      font-size: 2.2rem;
      font-weight: bold;
      font-family: $Muli;;
      color: $WTCBlack;
    }

    input[type="checkbox"] {
      margin: 0.2rem 0.5rem 0 0;
      display: inline;
    }

    .actions-toolbar {
      button, a {
        margin: 1rem 0;
      }
    }

    .control {
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="password"] {
        width: 100%;
      }

      .nested {
        margin-top: 2rem;
      }
    }

    .actions-toolbar {
      .no-display {
        display: none;
      }
      button {
        height: 4.4rem;
        width: 100%;

        &.primary {
          margin-bottom: 1rem;
          background: $WTCBlue;
        }

        &.tocart {
          background: $WTCBlue;
          color: $PureWhite;
          width: 100%;
          @include btn-large();

          @include glyphicon-shopping-cart;

          &:before {
            @include glyphicon;
          }
        }
      }

      a.back {
        @include btn();
        @include btn-black();
        margin-bottom: 1rem;
      }
    }

    /* WISHLIST STYLES */
    .form-wishlist-items {
      .price-wrapper {
        .price {
          font-weight: bold;
        }
      }
      ol.product-items {
        li.product-item {
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          border-bottom: 0.2rem solid $WTCGray1;
        }
        padding: 0;
        list-style-type: none;
        .product-item-info {
          overflow: hidden;

          .product-item-photo {
            float: left;
            margin: 0 2rem 0 0;
          }
          .field.qty, .field.comment-box {
            display: none;
          }
          .box-tocart {
            float: left;
          }
          div.product-item-inner > div.product-item-actions {
            float: right;
          }
          .action {
            font-size: 2rem;
            &.edit {
              display: none;
            }
            &.delete {
              float: right;
              float: left;

              @include glyphicon-remove-circle;

              &:before {
                @include glyphicon;
              }
              &:hover {
                color: $WTCBlack;
                text-decoration: none;
              }
              span {
                display: none;
              }
            }
            &.tocart {
              padding: 0;
              background: transparent;
              color: $WTCBlue;
              box-shadow: none;

              @include glyphicon-shopping-cart;

              &:before {
                @include glyphicon;
              }
              &:hover {
                text-decoration: none;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }

    #cart-sidebar-reorder {
      list-style: none;
      margin: 0;
      padding-left: 0;

      li {
        margin: 1rem 0;

        div.control {
          display: inline;
          margin-left: 1rem;
        }
      }
    }

    .sidebar-2 .block-wishlist {
      ol {
        @include make-row;
        list-style-type: none;
        padding: 0;
        li.product-item {
          @include make-xs-column(6);
          overflow: hidden;
          .product-image-container {
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 auto;
          }
          .product-item-name, .price-box {
            display: none;
          }
          .product-item-actions {
            .actions-primary, .actions-secondary {
              padding: 1rem;
              width: 50%;
            }
            .actions-primary {
              text-align: right;
              float: right;
            }
            .actions-secondary {
              float: left;
            }
            .action {
              font-size: 2rem;
              &.edit {
                display: none;
              }
              &.delete {
                float: right;
                float: left;

                @include glyphicon-remove-circle;

                &:before {
                  @include glyphicon;
                }
                &:hover {
                  color: $WTCBlack;
                  text-decoration: none;
                }
                span {
                  display: none;
                }
              }
              &.tocart {
                padding: 0;
                background: transparent;
                color: $WTCBlue;

                @include glyphicon-shopping-cart;
                &:before {
                  @include glyphicon;
                }
                &:hover {
                  color: $WTCBlack;
                  text-decoration: none;
                }
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .message.info.empty,
  span.empty {
    font-style: italic;
    display: block;
    margin : 0 0 3rem;
  }
}

body.customer-account-forgotpassword,
body.customer-account-create {
  fieldset {
    max-width: 50rem;
    width: 100%;
  }
}

.customer-account-logoutsuccess {
  .page-title,
  .column.main {
    text-align: center;
    width: 100%;
  }

  .page-title-wrapper {
    padding-bottom: 0;
  }

  .column.main {
    p {
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 768px) {
  .customer-account-index .main-content .block:not(.widget) .block-title > .action, .customer-account-edit .main-content .block:not(.widget) .block-title > .action, .customer-account-forgotpassword .main-content .block:not(.widget) .block-title > .action, .customer-address-form .main-content .block:not(.widget) .block-title > .action, .sales-order-history .main-content .block:not(.widget) .block-title > .action, .wishlist-index-index .main-content .block:not(.widget) .block-title > .action, .wishlist-index-share .main-content .block:not(.widget) .block-title > .action, .downloadable-customer-products .main-content .block:not(.widget) .block-title > .action, .vault-cards-listaction .main-content .block:not(.widget) .block-title > .action, .magento-advancedcheckout-sku-index .main-content .block:not(.widget) .block-title > .action, .magento-customerbalance-info-index .main-content .block:not(.widget) .block-title > .action, .magento-giftcardaccount-customer-index .main-content .block:not(.widget) .block-title > .action, .newsletter-manage-index .main-content .block:not(.widget) .block-title > .action, .paypal-billing-agreement-index .main-content .block:not(.widget) .block-title > .action, .magento-giftregistry-index-index .main-content .block:not(.widget) .block-title > .action, .review-customer-index .main-content .block:not(.widget) .block-title > .action, .magento-reward-customer-info .main-content .block:not(.widget) .block-title > .action, .magento-invitation-index-index .main-content .block:not(.widget) .block-title > .action, .customer-account-logoutsuccess .main-content .block:not(.widget) .block-title > .action, .customer-account-create .main-content .block:not(.widget) .block-title > .action, .sales-order-view .main-content .block:not(.widget) .block-title > .action, .customer-address-index .main-content .block:not(.widget) .block-title > .action, .customer-approve-review .main-content .block:not(.widget) .block-title > .action {
    float: right;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    width: auto;
  }
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  .customer-account-index,
  .customer-approve-review,
  .customer-order-review,
  .customer-account-edit,
  .customer-account-forgotpassword,
  .customer-address-form,
  .sales-order-history,
  .wishlist-index-index,
  .wishlist-index-share,
  .customer-approve-view,
  .downloadable-customer-products,
  .vault-cards-listaction,
  .magento-advancedcheckout-sku-index,
  .magento-customerbalance-info-index,
  .magento-giftcardaccount-customer-index,
  .newsletter-manage-index,
  .paypal-billing-agreement-index,
  .magento-giftregistry-index-index,
  .review-customer-index,
  .magento-reward-customer-info,
  .magento-invitation-index-index,
  .customer-account-logoutsuccess,
  .customer-account-create,
  .sales-order-view,
  .customer-address-index,
  .account {

    .main-content {
      padding-top: 11.2rem;

       .page-title-wrapper {
         margin-bottom: 3rem;
       }

       .columns {
          /*max-width: 98rem;*/
          @include make-row;
          margin: auto;
        }

        .box .box-title {
          margin: 0 0 1rem;
          font-size: 1.7rem;
        }

        .box-content p {
          margin: 1rem 0;
        }

        .order-status {
          margin-top: 0.6rem;
          padding: 0.5rem 1rem;
        }

        .column.main {
          /*width: 60%;*/
          @include make-md-column-offset(1);
          @include make-md-column(8);
          &:only-child {
            @include make-md-column(12);
            margin: 0;
            padding: 0;
          }
        }

        .sidebar-one {
          background-color: $OffWhite;
          border: 0.1rem solid $WTCGray1;
          box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,0.12), 0 0.1rem 0.2rem 0 rgba(0,0,0,0.24);
          border-radius: .1rem;
          display: block;
          float: left;
          position: relative;
          padding-left: 0;
          top: auto;
          bottom: 0;
          margin-top: 3rem;
          margin-bottom: 3rem;
          position: static;
          transition: none;
          -webkit-transition: none;

          .block.account-nav {
            .title {
              display: none;
            }

            .content {
              ul.nav {
                display: block !important;
                /*fixes bug where desktop menu is hidden if the menu had been closed in mobile view.*/
                /*!important is necessary here unless jQuery is changed to check for viewport resize.*/
              }
            }
          }
        }

        .sidebar-2 {
          font-size: 1.4rem;
          margin-top: 2rem;
          padding: 2.5rem 2rem;
          clear: left;
          float: left;
          .block-title {
            display: block;
            font-size: 1.8rem;
            line-height: 1.33;
            letter-spacing: .20.5rem;
            @include font-open-sans;
            text-transform: none;
          }
        }

        .columns {
          @include make-row;
        }

        .column.main {
          float: right;
          .block-content {
            @include make-row();
            margin: 0;
            .box {
              @include make-md-column(6);
              &:only-child {
                @include make-md-column(12);
              }
              .box-title {
                display: block;
                margin: 0 0 1.2rem;
              }
              .box-actions {
                padding: .5rem 0;
                margin-top: 0;
                a {
                  font-size: 1.3rem;
                }
              }
              .box-content p {
                margin: 0
              }
            }
          }
        }


    .form-wishlist-items {

      .actions-toolbar {
        button, a {
          margin: 0;
        }
        .primary {
          width: 75%;
          display: block;
          a, button {
            float: left;
            width: auto;
            margin-right: 1rem;
          }
        }
        .secondary {
          width: 25%;
          float: right;
        }
      }
      ol.product-items {
        .product-item-info {
          .action {
          font-size: 2rem;

          &.delete {
            @include btn;
            @include btn-white;
            @include btn-a-large;
            span {
              display: inline;
            }
          }
          &.tocart {
            @include btn;
            @include btn-primary;
            @include btn-large;
            span {
              display: inline;
            }
          }
        }
        }
      }
    }

    .form-address-edit {
      @include make-row();
      fieldset {
        @include make-md-column(6);
        &:only-child {
          @include make-md-column(12);
        }
      }
    }
  }

    .main-content .sidebar-one ul.nav {
      padding: 2rem 0;
      li.item {
        width: 100%;
        padding: 0;
        -webkit-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;

        a,
        strong {
          display: block;
          width: 100%;
          padding: 0.8rem 2rem;
        }

        &.current {
          padding-left: 0;
          border-left: 0.2rem solid $WTCBlue;
          a {
            padding: 0;
          }

          strong {
            padding-left: 1.8rem;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background: $WTCGray1;

          &.current {
            background: $OffWhite;
          }

          a {
            background: none;
          }
        }
      }
    }

    .message.info.empty {
      text-align: center;
      font-size: 1.7rem;
    }

    .actions-toolbar {
      clear: both;
      overflow: hidden;
      padding-bottom: 1rem;
      margin: 2rem 0;
      > .primary {
        padding: 0;
        text-align: left;
        display: inline-block;
        a.primary, button.primary, button {
          @include btn();
          @include btn-primary();
          @include btn-a-large();
          @include btn-large();
          display: inline-block;
          width: auto;
          &:hover {
            text-decoration: none;
          }
        }
        &:only-child {
          @include make-md-column(12);
          text-align: center;
        }
      }
      > .secondary {
         padding: 0;
         float: left;
         margin-right: 2rem;
         text-align: right;
         a.back, a.secondary, button.secondary, button {
          display: inline-block;
          width: auto;
         }
        &:only-child {
          @include make-md-column(12);
          text-align: center;
        }
      }
    }

    #reorder-validate-detail > .actions-toolbar {
      margin: 0;
      > div {
        padding: 0;
        width: 100%;
        text-align: left;
      }

    }
  }

  .customer-account-forgotpassword .main-content .column.main:only-child {
    padding: 0 1.5rem;
  }
}
