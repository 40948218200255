.multishipping-checkout-addresses,
.multishipping-checkout-address-newshipping {
  .page-wrapper {
    padding-top: 2rem;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  legend span {
    display: inline-block;
    margin-top: 3rem;
  }

  legend + .field {
    margin-top: 0;
  }

  .field {
    margin: 1rem 0;

    &.field-name-firstname,
    &.street {
      margin-top: 0;
    }
  }

  button,
  a.back {
    display: block;
    float: left;
    clear: left;
    margin: 1rem 0;
  }

  .field.choice {
    margin: 1rem 0;
  }

  input[type="checkbox"] {
    display: inline-block;
    margin-right: 1rem;
  }

  .primary button {
    @include btn-primary;
  }

  .secondary button {
    @include btn-primary;
  }

  .title {
    display: none;
  }

  .item-options {
    display: block;
    width: 100%;
    float: left;
    dt {
      font-weight: bold;
      clear: left;
      display: inline-block;
      margin-right: 1rem;
      float: left;
    }

    dd {
      display: inline-block;
      margin-left: 0;
      float: left;
    }
  }

  .field.address,
  .field.region,
  .field.country {
    .control {
      position: relative;

    }
  }

  .form-address-edit {
    max-width: 50rem;
  }

  #multiship-addresses-table {
    min-width: 60rem;

    tr {
      position: relative;
    }

    label {
      display: none;
    }

    a.delete {
      margin: 0;
      top: 1rem;
      position: relative;

      @include glyphicon-remove-circle;
      span {
        display: none;
      }

      &:before {
        @include glyphicon;
        font-size: 2rem;
        color: $WTCBlack;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        @include glyphicon-remove-circle;
      }
    }
  }
}
