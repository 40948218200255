footer {
  background-color: #EBEDF0;
  padding: 3rem 2.5rem;

  ul {
    margin-bottom: 4rem;
    &.social-links {
      text-align: center;
      li {
        margin: 0 4rem;
        display: inline-block;
        a {
          font-size: 2.5rem;
        }
      }
    }

    &.page-links,
    &.legal-links {
        li {
          margin: 1.5rem 0;

          a {
            text-decoration: underline;
          }
        }
      }
  }
}

/*** MEDIUM SCREEN STYLES  ***/
@media only screen and (min-width : 76.8rem) {

}


/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 992px) {
  footer {
    padding: 3rem 0;
    div.footer {
    }

    ul {
      li {
        display: inline-block;

        a {
          &:hover {
            text-decoration: none;
          }
        }
      }

      &.social-links {
              text-align: right;
                li {
                  margin: 0;

                  &:first-child {
                    margin-right: 5rem;
                  }
                }
      }

      &.page-links {

        li {
          margin: 0 3rem 0 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.legal-links {
        li {
          margin: 0 3rem 0 0;
        }
      }
    }

    .copyright {
      text-align: right;
      margin: 2rem 0;
      font-size: 1.6rem;
      line-height: 2.5rem;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/*** LARGE SCREEN STYLES  ***/
@media only screen and (min-width : 1200px) {

}
